import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import trc from "../assets/svg/trc.svg";
import grafic from "../assets/svg/grafic.svg";
import copy from "../assets/svg/copy.svg";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

export default function Deposit() {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [title, setTitle] = useState("My Boxes Actives");

  useEffect(() => {
    const address = window.localStorage.getItem("address");
    if (address) {
      setAddress(address);
    }
  }, []);

  const copyShared = () => {
    navigator.clipboard.writeText(address);
    toast.success(t("_WALLET._SHARED"));
  };
  return (
    <>
      <section className="container-full bg1">
        <div className="container" style={{ color: "black" }}>
          <h1 className="title-main">
            {t("_BUTTONS._DEPOSIT")}
            <div className="title-main-button">
              <Link to="/wallet" className="">
                <button className="purple">{t("_BUTTONS._BACK")}</button>
              </Link>
            </div>
          </h1>
          <div>
            <div className="d-lg-grid d-sm-flex grid-auto-200 gap-b-mobile">
              <div className="signin retiro gap-b-mobile">
                <div className="container-blue signin-inner retiro w-100 mt-0">
                  <div
                    className="row d-lg-grid d-sm-flex align-items-center g-3"
                    style={{ gridTemplateColumns: "auto 200px" }}
                  >
                    <div
                      className="montserrat d-flex flex-column justify-content-evenly"
                      style={{ height: "100%" }}
                    >
                      <h6 className="ta-center color-blue fw-bold">
                        {t("_WALLET._TITLE_QR_1")} <br />{" "}
                        {t("_WALLET._TITLE_QR_2")}
                      </h6>
                      <p className="ta-center fw-light">
                        jkasidasjdfasjdSDASDsakdasdasdasdas
                        <img
                          onClick={() => copyShared()}
                          src={copy}
                          alt="copy"
                          className="m-1"
                        />
                      </p>
                      <p className="ta-center fw-light">
                        {t("_WALLET._TEXT_QR")} 11 USDT
                      </p>
                    </div>
                    <div className="qr d-flex justify-content-center">
                      <QRCode size={256} value={address} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="grid purple d-flex justify-content-between flex-column align-items-center position-relative w-200 p-10-10-90"
                style={{ paddingBottom: "0px" }}
              >
                <h6 className="fs-14">
                  {t("_WALLET._NETWORK")}: TRC-20 - USDT
                </h6>
                <img
                  src={trc}
                  alt="trc" className="width-img-mobile "
                  style={{ width: "90%", marginRight: "0px" }}
                />
                <img
                  style={{ width: "calc(100% + 20px)", marginRight: "0px" }}
                  src={grafic}
                  alt=""
                />
              </div>
            </div>
            <div className="mainchart px-3 px-md-4 py-3 py-lg-4 text-white mt-lg-5">
              <div>
                <h5 className="mb-0">{t("_WALLET._TABLE3")}</h5>
              </div>
              <div className="recent-contact pb-2 pt-3">
                <table>
                  <thead>
                    <tr className="border-b2">
                      <th>{t("_WALLET._TABLE23_A")}</th>
                      <th>{t("_WALLET._TABLE23_B")}</th>
                      <th>{t("_WALLET._TABLE23_C")}</th>
                      <th>{t("_WALLET._TABLE23_D")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b2">
                      <td>Orden: #148</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td>
                        <span className="green-tag">
                          {t("_STATUSES._COMPLETED")}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #432</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td>
                        <span className="yellow-tag">
                          {t("_STATUSES._PENDING")}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #765</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td>
                        <span className="red-tag">
                          {t("_STATUSES._REJECTED")}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #148</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td>
                        <span className="green-tag">
                          {t("_STATUSES._COMPLETED")}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #432</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td>
                        <span className="yellow-tag">
                          {t("_STATUSES._PENDING")}
                        </span>
                      </td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #765</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td>
                        <span className="red-tag">
                          {t("_STATUSES._REJECTED")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
