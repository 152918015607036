export const eng = {
  _BUTTONS: {
    _CONFIRM: "Confirm",
    _BACK: "Back",
    _INVITE: "Invite",
    _WITHDRAW: "Withdraw",
    _DEPOSIT: "Deposit",
    _INVITE_WIN: "Invite and win",
    _EDIT: "Edit",
  },
  _STATUSES: {
    _COMPLETED: "Completed",
    _REJECTED: "Rejected",
    _PENDING: "Pending",
  },
  _SIDEBAR: {
    _DASHBOARD: "Dashboard",
    _BOTTRADING: "Bot trading",
    _PACKAGES: "Packages",
    _WALLET: "My wallet",
    _REFERRALS: "Referrals",
    _ACTIVITIES: "Activities",
    _SETTINGS: "Settings",
    _SWAP: "Swap",
  },
  _HEADER: {
    _SEARCH: "Search...",
    _NOTIFICATIONS: "Notifications",
    _WELCOME: "Welcome",
  },
  _MODALRECHARGE: {
    _TITLE: "Insufficient Funds!",
    _DESCRIPTION:
      "It looks like your account needs a boost. Head to the Wallet page to reload and continue your exciting investment journey with Smartboxes.",
    _ACTION: "Reload Now",
  },
  _MODALBUY: {
    _TITLE: "Confirm Purchase",
    _DESCRIPTION:
      " Are you sure you want to proceed with this purchase? Double-check the details before confirming.",
    _ACTION: "Confirm Purchase ",
    _SUCCESS: "Buy Confirm",
  },
  _BOARD: {
    _BACK: "Back",
    _BUY: "Buy",
    _MY: "My Box",
    _PHONE: "Please rotate your device!",
  },
  _WALLET: {
    _TITLE: "Wallet",
    _BALANCE: "Total balance",
    _EXCHANGE: "Exchange Balance",
    _TEAMS: "Teams Balance",
    _TABLE1: "Todays Cryptocurrency Prices",
    _TABLE1_A: "Asset",
    _TABLE1_B: "On orders",
    _TABLE1_C: "Available balance",
    _TABLE1_D: "Total balance",
    _TABLE1_E: "24h market",
    _TEXT1:
      "Top up your account effortlessly to kickstart your investment journey with Smartboxes. Use the wallet address provided below or scan the QR code. Be sure to follow our instructions for a seamless transaction.",
    _LI1: "BNB Network:",
    _LIP1:
      "Conduct your deposit exclusively  through the Binance Coin (BNB) network.",
    _LI2: "Amount and Fee:",
    _LIP2:
      "Deposit the amount of the package you wish to purchase plus an additional $1 to cover the transaction fee. For example, if you want to buy a $50 package, deposit $51.",
    _LI3: "User Responsibility:",
    _LIP3:
      "We are not liable for fund losses due to errors in the BNB network or incorrect wallet addresses. Double-check carefully before confirming the transaction.",
    _COPY: "Copy Wallet",
    _SHARED: "Address copied to clipboard successfully.",
    _WITHDRAW_ADDRESS: "Withdrawal address",
    _AMOUNT_RECEIVE: "Amount to receive",
    _AMOUNT_DEDITED: "Amount to be debited",
    _PASSWORD_WITHDRAW: "Password withdrawal",
    _WITHDRAWAL_FEE: "Withdrawal fee:",
    _2FA_CODE: "2FA code",
    _NETWORK: "Network",
    _TABLE2: "Withdrawal history",
    _TABLE3: "Deposit history",
    _TABLE23_A: "Order",
    _TABLE23_B: "Amount",
    _TABLE23_C: "Date",
    _TABLE23_D: "Status",
    _TITLE_QR_1: "Scan the following QR",
    _TITLE_QR_2: "or copy the address",
    _TEXT_QR: "minimum deposit",
  },
  _REFERRALS: {
    _TITLE: "Referrals",
    _INVITE_WIN: "Invite and win",
    _TABLE1: "Personal growth",
    _TABLE1_A: "Jan",
    _TABLE1_B: "Feb",
    _TABLE1_C: "Mar",
    _TABLE1_D: "Apr",
    _TABLE1_E: "May",
    _TABLE1_F: "Jun",
    _TABLE1_G: "Jul",
    _TABLE1_H: "Aug",
    _TABLE1_I: "Sep",
    _TABLE1_J: "Oct",
    _TABLE1_K: "Nov",
    _TABLE1_L: "Dec",
    _TABLE2_A: "Type",
    _TABLE2_B: "Range",
    _TABLE2_C: "Level",
    _INVITE_WIN_TITLE: "Invite friends and earn rewards at ProfitWave",
    _INVITE_WIN_TEXT1:
      "Share the code below or have them enter it during the registration process.",
    _INVITE_WIN_TEXT2:
      "You will be rewarded when your friends complete their task.",
  },
  _ACTIVITIES: {
    _TITLE: "Activities",
    _TABLE1: "Active overall growth",
    _TABLE1_A: "Type",
    _TABLE1_B: "Asset",
    _TABLE1_C: "Date",
    _TABLE1_D: "IP address",
    _TABLE1_E: "Status list",
    _TABLE1_F: "Amount",
  },
  _PROFILE: {
    _TITLE: "Profile",
    _USERNAME: "Username",
    _WALLETRECEIVE: "Wallet receiving profits",
    _PASSWORD: "Password",
    _ACTION: "Update",
    _L1: "BNB Network:",
    _L1P: "Conduct your deposit exclusively through the Binance Coin (BNB) network.",
    _L2: "User Responsibility",
    _L2P: "We are not liable for fund losses due to errors in the BNB network or incorrect wallet addresses. Double-check carefully before confirming the transaction.",
    _TOASTSUCCESS: "Data modified successfully",
    _TOASTERROR: "An error occurred, contact support",
  },
  _LOGIN: {
    _TITLE: "Login",
    _EMAIL: "Your email",
    _PASSWORD: "Your password · · · ·",
    _METAMASK: "Login with Metamask",
    _LOGIN: "Login",
    _FORGOTPASSWORD: "Forgot your password?",
  },
  _REGISTER: {
    _REGISTER: "Crear cuenta",
    _NAME: "Name",
    _LASTNAME: "Last name",
    _COUNTRY: "Country",
    _SPONSOR: "Sponsor",
    _SEARCHSPONSOR: "Search Sponsor",
    _EMAIL: "Your email",
    _CONFIRMEMAIL: "Confirm email",
    _PASSWORD: "Your password · · · ·",
    _CONFIRMPASSWORD: "Confirm password · · · ·",
    _TERMS: "terms and conditions",
    _ACEPT: "I accept ",
    _REGISTER: "Create account",
    _NOPARNETDESCRIPTION:
      "You need an invitation code from a partner to enter our Profitwave system, where blockchain technology and smart contracts multiply your investments up to x8. Contact someone who invites you to the business and discover new financial opportunities!",
    _ERROR: "The email and username must be unique, try another one.",
    _POLICY1:
      " By pressing the 'Accept' button below, you confirm  that you have read and agreed to our",
    _POLICY2: "privacy policy and terms of service.",
    _ALREDY: "I already have an account",
    _WITHDRAWAL:
      "The intelligent system will promptly process any  received donations, automatically forwarding them to a BEP-20 wallet. We do not assume responsibility for any mistakes; kindly enter the BEP-20 wallet address accurately. You will have the option to modify it later if you wish.",
    _REQUIRED: "This field is required",
    _EMAIL: "Enter your email",
    _MINIUM: "Minimum 6 characters and 1 number.",
    _MINIUMUSER: "Minimum 4 characters",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Forgot your password?",
    _EMAIL: "Your email",
    _BUTTON: "Send email",
  },
  _DASHBOARD: {
    _TITLE: "Dashboard",
    _TABLE1: "Monthly overall growth",
    _TABLE2: "Active overall growth",
    _TABLE2_A: "Type",
    _TABLE2_B: "Asset",
    _TABLE2_C: "Date",
    _TABLE2_D: "IP address",
    _TABLE2_E: "Status list",
    _TABLE2_F: "Amount",
    _TABLE3: "Recent transaction",
    _BALANCE: "Total balance",
  },
  _BOTTRADING: {
    _BOTTRADING: "Bot trading",
    _TABLE1: "Market Opportunities",
    _TABLE1_A: "Tokens",
    _TABLE1_B: "Tokens",
    _TABLE1_C: "Profit",
    _TABLE2: "AI Aggreator",
    _TABLE2_A: "Balance",
    _TABLE2_B: "Token 1",
    _TABLE2_C: "Token 2",
    _TABLE2_D: "Details",
    _TABLE2_D_1: "Profit Percent",
    _TABLE2_D_2: "Profit Amount",
    _TABLE2_D_3: "Total Traders",
    _TABLE2_D_4: "Total Volumen",
    _TABLE2_D_5: "Total Trade Limit",
    _TABLE3: "Operation History",
    _TABLE3_A: "Date",
    _TABLE3_B: "TRX",
    _TABLE3_C: "Profit",
    _TABLE3_D: "Status",
  },
  _PACKAGES: {
    _PACKAGES: "Investment packages",
    _PACKAGE: "Package ",
    _BUTTON1: "Information",
    _BUTTON2: "Buy",
    _PROFIT: "Profits of ",
    _CAPITAL_A: "Including Capital",
    _CAPITAL_B: "up to ",
    _PLANS: "Simultaneous plans",
    _MYPACKAGES: "My packages",
    _MYPACKAGES_A: "Total generated",
  },
  _SETTINGS: {
    _TITLE: "Settings",
    _TABLE1: "Edit profile",
    _TABLE1_A: "Name",
    _TABLE1_B: "Last name",
    _TABLE1_C: "Country",
    _TABLE1_D: "Email",
    _TABLE1_E: "Password · · · ·",
    _TABLE1_F: "Confirm password · · · ·",
    _LEVEL: "Level",
    _TOTAL_BALANCE: "Total balance",
  },
  _COUNTDOWN: {
    _TITLE: "Next cut:",
    _DAYS: "Days",
    _HOURS: "Hours",
    _MINUTES: "Minutes",
    _SECONDS: "Seconds",
  },
};
