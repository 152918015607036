import React, { useState, useEffect, useContext } from "react";
import barras from "../assets/svg/barras.svg";
import barrasverdes from "../assets/svg/barrasverdes.svg";
import dolar from "../assets/svg/dolar.svg";
import grafic from "../assets/svg/grafic.svg";
import AppContext from "../context/AppContex";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import bite from '../assets/airdrop/bite.jpg';
import okk from '../assets/airdrop/okk.jpg';
import binance from '../assets/airdrop/binance.jpg';
import twitter from '../assets/airdrop/twitter.jpg';
import youtube from '../assets/airdrop/youtube.jpg';
import telegram from '../assets/airdrop/telegram.jpg';
import share from '../assets/airdrop/share.jpg';
import invite from '../assets/airdrop/invite.jpg';

export default function Airdrop() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { updateLoading } = useContext(AppContext);
    const [data, setData] = useState([]);
    const [buttonStatus, setButtonStatus] = useState({
        share: false,
        telegram: false,
        youtube: false,
        twitter: false,
        binance: false,
        okk: false,
        bite: false,
        invite: false
    });

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = () => {
        updateLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND}/notifications?limit=10&page=0`, {
            method: "GET",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response?.status !== false) {
                    setData(response?.data?.items);
                }
                updateLoading(false);
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    };

    const handleShare = () => {
        setButtonStatus({ ...buttonStatus, share: true });
    };

    const handleTelegram = () => {
        setButtonStatus({ ...buttonStatus, telegram: true });
        window.open("https://t.me/wavees", "_blank");
    };

    const handleYouTube = () => {
        setButtonStatus({ ...buttonStatus, youtube: true });
        window.open("https://www.youtube.com/channel/wavees", "_blank");
    };

    const handleTwitter = () => {
        setButtonStatus({ ...buttonStatus, twitter: true });
        const tweetText = "Unete a Wavees 🌊";  // Texto predefinido
        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&via=binance`;
        window.open(tweetUrl, "_blank");
    };

    const handleBinance = () => {
        setButtonStatus({ ...buttonStatus, binance: true });
        const tweetText = "Unete a Wavees 🌊";  // Texto predefinido
        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&via=binance`;
        window.open(tweetUrl, "_blank");
    };

    const handleOKK = () => {
        setButtonStatus({ ...buttonStatus, okk: true });
        const tweetText = "Unete a Wavees 🌊";  // Texto predefinido
        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&via=okx`;
        window.open(tweetUrl, "_blank");
    };

    const handleBite = () => {
        setButtonStatus({ ...buttonStatus, bite: true });
        const tweetText = "Unete a Wavees 🌊";  // Texto predefinido
        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&via=bybit`;
        window.open(tweetUrl, "_blank");
    };

    const handleInvite = () => {
        setButtonStatus({ ...buttonStatus, invite: true });
        // Aquí puedes agregar la lógica de invitar a usuarios, por ejemplo, abrir un modal o un enlace de invitación.
        alert("Invita a 3 usuarios con wallets para unirte a Wavees.");
    };

    return (
        <>
            <div className="row my-2 g-3 g-lg-4 pb-3 exchange">
                <div className="col-12">
                    <div className="mainchart px-3 px-md-4 py-3 py-lg-4">
                        <div className="d-flex align-items-center gap-2 mb-1">
                            <span className="material-symbols-outlined fw-light">
                                featured_seasonal_and_gifts
                            </span>
                            <span className="fw-bold text-white">Wavees: Únete al Airdrop que Revolucionará la Comunidad</span>
                        </div>
                        <p className="mb-0 pb-4 border-b">En Wavees, estamos construyendo una comunidad sólida donde tus acciones valen oro. Participa en nuestro airdrop exclusivo completando tareas sencillas que ayudarán a expandir el proyecto. ¡El futuro está en tus manos!</p>
                        <div className="row g-3 g-lg-4">
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="promotion-card">
                                    <div className="promo-img">
                                        <img src={share} className="img-fluid rounded-lg" alt="" />
                                        <span className="promo-tag">Sep 01st - Oct 31st</span>
                                    </div>
                                    <div className="promo-content">
                                        <p className="fw-bold text-white large">Comparte tu experiencia con Wavees y ayuda a expandir nuestra comunidad.</p>
                                        <button
                                            className="outline-btn-lg d-flex align-items-center gap-2"
                                            disabled={buttonStatus.share}
                                            onClick={handleShare}
                                        >
                                            {buttonStatus.share ? "Reclamado" : "Realizar tarea"} <span className="material-symbols-outlined">arrow_right_alt</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="promotion-card">
                                    <div className="promo-img">
                                        <img src={telegram} className="img-fluid rounded-xl" alt="" />
                                        <span className="promo-tag">Sep 01st - Oct 31st</span>
                                    </div>
                                    <div className="promo-content">
                                        <p className="fw-bold text-white large">Únete a nuestro canal de Telegram para recibir las últimas actualizaciones de Wavees.</p>
                                        <button
                                            className="outline-btn-lg d-flex align-items-center gap-2"
                                            disabled={buttonStatus.telegram}
                                            onClick={handleTelegram}
                                        >
                                            {buttonStatus.telegram ? "Reclamado" : "Realizar tarea"} <span className="material-symbols-outlined">arrow_right_alt</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="promotion-card">
                                    <div className="promo-img">
                                        <img src={youtube} className="img-fluid" alt="" />
                                        <span className="promo-tag">Sep 01st - Oct 31st</span>
                                    </div>
                                    <div className="promo-content">
                                        <p className="fw-bold text-white large">Suscríbete a nuestro canal de YouTube para tutoriales y noticias sobre Wavees.</p>
                                        <button
                                            className="outline-btn-lg d-flex align-items-center gap-2"
                                            disabled={buttonStatus.youtube}
                                            onClick={handleYouTube}
                                        >
                                            {buttonStatus.youtube ? "Reclamado" : "Realizar tarea"} <span className="material-symbols-outlined">arrow_right_alt</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="promotion-card">
                                    <div className="promo-img">
                                        <img src={twitter} className="img-fluid" alt="" />
                                        <span className="promo-tag">Sep 01st - Oct 31st</span>
                                    </div>
                                    <div className="promo-content">
                                        <p className="fw-bold text-white large">Síguenos en Twitter para mantenerte al día con las últimas novedades.</p>
                                        <button
                                            className="outline-btn-lg d-flex align-items-center gap-2"
                                            disabled={buttonStatus.twitter}
                                            onClick={handleTwitter}
                                        >
                                            {buttonStatus.twitter ? "Reclamado" : "Realizar tarea"} <span className="material-symbols-outlined">arrow_right_alt</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="promotion-card">
                                    <div className="promo-img">
                                        <img src={binance} className="img-fluid" alt="" />
                                        <span className="promo-tag">Sep 01st - Oct 31st</span>
                                    </div>
                                    <div className="promo-content">
                                        <p className="fw-bold text-white large">Envía un mensaje promocionando Wavees en Binance X.com.</p>
                                        <button
                                            className="outline-btn-lg d-flex align-items-center gap-2"
                                            disabled={buttonStatus.binance}
                                            onClick={handleBinance}
                                        >
                                            {buttonStatus.binance ? "Reclamado" : "Realizar tarea"} <span className="material-symbols-outlined">arrow_right_alt</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="promotion-card">
                                    <div className="promo-img">
                                        <img src={okk} className="img-fluid" alt="" />
                                        <span className="promo-tag">Sep 01st - Oct 31st</span>
                                    </div>
                                    <div className="promo-content">
                                        <p className="fw-bold text-white large">Envía un mensaje promocionando Wavees en OKX X.com.</p>
                                        <button
                                            className="outline-btn-lg d-flex align-items-center gap-2"
                                            disabled={buttonStatus.okk}
                                            onClick={handleOKK}
                                        >
                                            {buttonStatus.okk ? "Reclamado" : "Realizar tarea"} <span className="material-symbols-outlined">arrow_right_alt</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="promotion-card">
                                    <div className="promo-img">
                                        <img src={bite} className="img-fluid" alt="" />
                                        <span className="promo-tag">Sep 01st - Oct 31st</span>
                                    </div>
                                    <div className="promo-content">
                                        <p className="fw-bold text-white large">Envía un mensaje promocionando Wavees en ByBit X.com.</p>
                                        <button
                                            className="outline-btn-lg d-flex align-items-center gap-2"
                                            disabled={buttonStatus.bite}
                                            onClick={handleBite}
                                        >
                                            {buttonStatus.bite ? "Reclamado" : "Realizar tarea"} <span className="material-symbols-outlined">arrow_right_alt</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-xxl-3">
                                <div className="promotion-card">
                                    <div className="promo-img">
                                        <img src={invite} className="img-fluid" alt="" />
                                        <span className="promo-tag">Sep 01st - Oct 31st</span>
                                    </div>
                                    <div className="promo-content">
                                        <p className="fw-bold text-white large">Invita a 3 usuarios con wallets para unirte a Wavees y ganar más recompensas.</p>
                                        <button
                                            className="outline-btn-lg d-flex align-items-center gap-2"
                                            disabled={buttonStatus.invite}
                                            onClick={handleInvite}
                                        >
                                            {buttonStatus.invite ? "Reclamado" : "Realizar tarea"} <span className="material-symbols-outlined">arrow_right_alt</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
