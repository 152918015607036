import React, { Component, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContex";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [balanceApproved, setBalanceApproved] = useState(false);

  const changeLng = (language) => {
    localStorage.setItem("lng", language);
    i18n.changeLanguage(language);
  };

  return (
    <div id="select-container">
      <select onChange={(e) => changeLng(e.target.value)} value={i18n.language}>
        <option value="en">Inglés</option>
        <option value="es">Español</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
