import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthInterceptor } from "./AuthInterceptor";
import AppContext from "./context/AppContex";
import useInitialState from "./hooks/useInitialState";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Layout from "./Layout";
import LayoutAdmin from "./LayoutAdmin";
import Login from "./pagess/Login";
import Register from "./pagess/Register";
import Bot from "./pagess/Bot";
import NotFound from "./pagess/NotFound";
import RecoverPassword from "./pagess/RecoverPassword";
import Packages from "./pagess/Packages";
import Activity from "./pagess/Activity";
import Home from "./pagess/Home";
import Withdrawal from "./pagess/Withdrawal";
import Deposit from "./pagess/Deposit";
import Settings from "./pagess/Settings";
import Wallet from "./pagess/Wallet";
import Referrals from "./pagess/Referrals";
import Notifications from "./pagess/Notifications";
import Airdrop from "./pagess/Airdrop";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { config } from "./web3/config";

const queryClient = new QueryClient();
const projectId = "bcbdb3e391c0b0d45a00fe641abc4106";
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});
export default function App() {
  const initialState = useInitialState();

  useEffect(() => {
    AuthInterceptor();
  }, []);

  return (
    <>
      <AppContext.Provider value={initialState}>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <I18nextProvider i18n={i18n}>
                <Layout>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <Home />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="trading"
                      element={
                        <PrivateRoute>
                          <Bot />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="packages"
                      element={
                        <PrivateRoute>
                          <Packages />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="activity"
                      element={
                        <PrivateRoute>
                          <Activity />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="settings"
                      element={
                        <PrivateRoute>
                          <Settings />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="wallet"
                      element={
                        <PrivateRoute>
                          <Wallet />
                        </PrivateRoute>
                      }
                    ></Route>

                    <Route
                      path="airdrop"
                      element={
                        <PrivateRoute>
                          <Airdrop />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="referrals"
                      element={
                        <PrivateRoute>
                          <Referrals />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="wallet/deposit"
                      element={
                        <PrivateRoute>
                          <Deposit />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="wallet/withdrawal"
                      element={
                        <PrivateRoute>
                          <Withdrawal />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="notifications"
                      element={
                        <PrivateRoute>
                          <Notifications />
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route path="/signin" element={<Login />} />
                    <Route path="/signup" element={<Register />} />
                    <Route
                      path="/recover-password"
                      element={<RecoverPassword />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Layout>
              </I18nextProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </WagmiProvider>
      </AppContext.Provider>
    </>
  );
}

function PrivateRoute({ children }: any) {
  const [state, setState] = useState("loading");
  useEffect(() => {
    (async function () {
      try {
        const accountAddress = window.localStorage.getItem("token");
        setState(accountAddress ? "loggedin" : "redirect");
      } catch {
        setState("redirect");
      }
    })();
  }, []);
  if (state === "loading") {
    return <div>Loading..</div>;
  }
  if (state === "redirect") {
    return <Navigate to="/signin" />;
  }
  return <LayoutAdmin> {children} </LayoutAdmin>;
}
