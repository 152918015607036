import React, { useState, useContext, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import AppContext from "../context/AppContex";
import toast from "react-hot-toast";
import {
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { parseEther, parseUnits, isAddress } from "viem";
import { abi } from "../abi/pool";
import { useAccount } from "wagmi";

export default function Claim() {
  const { address } = useAccount();
  const { t } = useTranslation();
  const { loading, updateLoading } = useContext(AppContext);
  const [claim, setClaim] = useState([]);
  const [claimType, setClaimType] = useState(0);
  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const REACT_APP_CONTRACT = process.env.REACT_APP_CONTRACT ?? ""; // Dirección

  useEffect(() => {
    getClaim();
  }, []);

  const getClaim = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/list-claim`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status !== false) {
          console.log(response);
          setClaim(response?.data); // Guardamos los datos mapeados
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const signerClaim = (typeClaim) => {
    console.log(claim?.nonce)
    if (typeClaim > claim?.nonce) {
      toast.error("Primero debes reclamar la anterior recompensa");
      return;
    }
    updateLoading(true);
    setClaimType(typeClaim);
    const formData = new FormData();
    formData.append("claim", typeClaim);
    fetch(`${process.env.REACT_APP_BACKEND}/signer-claim`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        claimWrite(JSON.parse(response.signer));
      })
      .catch((error) => {
        updateLoading(false);
        toast.error("Vuelve a intentarlo");
      });
  };

  async function claimWrite(hash) {
    console.log(hash);
    console.log(address);
    writeContract({
      address: REACT_APP_CONTRACT, //address contrato
      abi,
      functionName: "executeFlashLoan",
      args: [address, hash],
    });
  }

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    if (hash) {
      completedClaim(hash);
    }
  }, [hash]);

  useEffect(() => {
    if (isConfirmed) {
      console.log("Pucharse Succesfull");
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (error) {
      toast.error(error.shortMessage || error.message);
      updateLoading(false);
    }
  }, [error]);


  const completedClaim = (hash) => {
    const formData = new FormData();
    formData.append("claim", claimType);
    formData.append("trx", hash);
    fetch(`${process.env.REACT_APP_BACKEND}/completed-claim`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status === false) {
          toast.error("El Claim no se completo, vuelve a intentarlo.");
        } else {
          toast.success("Claim completado con exito");
          getClaim()
        }
        updateLoading(false);
      })
      .catch((error) => {
        updateLoading(false);
        console.log(error);
        toast.error("Vuelve a intentarlo");
      });
  };


  const handleBinance = () => {
    const tweetText = "Unete a Wavees 🌊";  // Texto predefinido
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&via=binance`;
    window.open(tweetUrl, "_blank");
    signerClaim(4)
  };
  const handleTelegram = () => {
    window.open("https://t.me/wavees", "_blank");
    signerClaim(3)
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-12">
        <ul id="progressbar" className="text-center">
          <li
            className={`${claim?.nonce && claim?.nonce > 1 ? "active" : ""
              } step0`}
          >
            Completed Profile
            <br />
            {claim?.nonce && claim?.nonce > 1 ? (
              <button className="outline-btn mt-1 outline-completed">
                Completed 5 🌊
              </button>
            ) : (
              <button
                className="outline-btn mt-1"
                onClick={() => signerClaim(1)}
                disabled={isPending || !claim?.profile}
              >
                Claim 5 🌊
              </button>
            )}
          </li>
          <li
            className={`${claim?.nonce && claim?.nonce > 1 ? "active" : ""
              } step0`}
          >
            Invita 1 Persona
            <br />
            {claim?.nonce && claim?.nonce > 2 ? (
              <button className="outline-btn mt-1 outline-completed">
                Completed 5 🌊
              </button>
            ) : (
              <button
                className="outline-btn mt-1"
                onClick={() => signerClaim(2)}
                disabled={isPending || !claim?.referral}
              >
                Claim 5 🌊
              </button>
            )}
          </li>
          <li
            className={`${claim?.nonce && claim?.nonce > 3 ? "active" : ""
              } step0`}
          >
            Unirte a nuestro Telegram
            <br />
            {claim?.nonce && claim?.nonce > 3 ? (
              <button className="outline-btn mt-1 outline-completed">
                Completed 5 🌊
              </button>
            ) : (
              <button
                className="outline-btn mt-1"
                onClick={() => handleTelegram()}
                disabled={isPending || !claim?.package}
              >
                Claim 5 🌊
              </button>
            )}
          </li>
          <li
            className={`${claim?.nonce && claim?.nonce > 1 ? "active" : ""
              } step0`}
          >
            Enviar Mensaje a BSC de  🌊
            <br />
            {claim?.nonce && claim?.nonce > 4 ? (
              <button className="outline-btn mt-1 outline-completed">
                Completed 5 🌊
              </button>
            ) : (
              <button
                className="outline-btn mt-1"
                onClick={() => handleBinance()}
                disabled={isPending || claim?.nonce < 4}
              >
                Claim 5 🌊
              </button>
            )}
          </li>
          <li
            className={`${claim?.nonce && claim?.nonce > 5 ? "active" : ""
              } step0`}
          >
            Package de 20$
            <br />
            {claim?.nonce && claim?.nonce > 5 ? (
              <button className="outline-btn mt-1 outline-completed">
                Completed
              </button>
            ) : (
              <button
                className="outline-btn mt-1"
                onClick={() => signerClaim(5)}
                disabled={isPending || claim?.nonce < 4}
              >
                Claim Package
              </button>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
