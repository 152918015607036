import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import copy from "../assets/svg/copy.svg";
import referrals from "../assets/png/referrals.png";
import AppContext from "../context/AppContex";
import Flag from 'react-world-flags'
import toast from "react-hot-toast";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";

export default function Referrals() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
  );

  const [isVisible, setIsVisible] = useState(true);
  const [codeReferral, setCodeReferral] = useState(localStorage.getItem("referral"));
  const { t } = useTranslation();
  const { updateLoading, loading } = useContext(AppContext);
  const [teamsList, setTeamlist] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getReferrals();
  }, [page, limit]);


  const getReferrals = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/referred?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status !== false) {
          console.log(response)
          setTeamlist(response.response);
          const countsByMonth = countReferralsByMonth(response.response);
          setData(countsByMonth)
          setTotal(response?.total)
        }
        updateLoading(false);
      })
      .catch((error) => {
        updateLoading(false);
        console.log(error);
      });
  };


  const countReferralsByMonth = (referrals) => {
    // Crear un objeto para contar los referrals por mes
    const counts = Array(12).fill(0); // Inicializar un array con 12 meses, todos en 0

    referrals.forEach((referral) => {
      const date = new Date(referral.created);
      const month = date.getMonth(); // Obtener el mes (0 = enero, 11 = diciembre)
      counts[month] += 1; // Sumar 1 al mes correspondiente
    });

    return counts; // Retornar el array con los contadores de cada mes
  };


  const chart2 = useRef();
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    animation: {
      easing: "easeInOutQuad",
      duration: 1200,
    },
    scales: {
      y: {
        ticks: {
          display: true,
        },
        grid: {
          display: true,
        },
        suggestedMin: 0,
      },
      x: {
        ticks: {
          display: true,
        },
        grid: {
          display: true,
        },
      },
    },
    elements: {
      point: {
        radius: 6,
      },
    },
  };
  const data2 = {
    labels: [t("_REFERRALS._TABLE1_A"), t("_REFERRALS._TABLE1_B"), t("_REFERRALS._TABLE1_C"), t("_REFERRALS._TABLE1_D"), t("_REFERRALS._TABLE1_E"), t("_REFERRALS._TABLE1_F"), t("_REFERRALS._TABLE1_G"), t("_REFERRALS._TABLE1_H"), t("_REFERRALS._TABLE1_I"), t("_REFERRALS._TABLE1_J"), t("_REFERRALS._TABLE1_K"), t("_REFERRALS._TABLE1_L")],
    datasets: [
      {
        data: data,
        borderColor: "#00F0FF",

        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(10, 10, 10, 55);
          gradient.addColorStop(0, "#00A2EA");
          gradient.addColorStop(1, "#6BE4C4");
          console.log(gradient);
          return gradient;
        },
        borderWidth: 1,
        fill: false,
        lineTension: 0.5,
      },
    ],
  };

  const data3 = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };


  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const copyShared = (value) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND}signup?invite=${value}`);
    toast.success(t("_WALLET._SHARED"));
  };



  function abbreviateString(str) {
    if (str.length <= 6) {
      return str; // Si el string es demasiado corto, no lo abrevies
    }

    const start = str.slice(0, 4); // Primeros 2 caracteres
    const end = str.slice(-6); // Últimos 4 caracteres
    return `${start}....${end}`;
  }
  return (
    <div>
      {isVisible &&
        <div>
          <h1 className="title-main">{t("_REFERRALS._TITLE")}</h1>
          <div className=" fs-5 d-flex gap-2 flex-wrap justify-content-center">
            <button onClick={toggleVisibility} className="social-login-button p-2 mb-4 full-block d-flex bg-yellow align-items-center justify-content-center gap-3 br-6 ">
              <span className="material-symbols-outlined fs-2" style={{ color: 'white' }}>group_add</span>
              {t("_REFERRALS._INVITE_WIN")}
            </button>
          </div>
          <div className="p-3 br-6" style={{ backgroundColor: "#0C1138" }}>
            <h5 className="mb-3">Directos </h5>
            <div className="chart">
              <Line options={options} data={data2} ref={chart2} />
            </div>
          </div>
          <div className="mainchart px-3 px-md-4 py-2 py-lg-4 text-white mt-5">
            <div className="recent-contact pb-2 ">
              <table>
                <thead>
                  <tr className="border-b2">
                    <th>{t("_REFERRALS._TABLE2_A")}</th>
                    <th>Username</th>
                    <th>Amount Total</th>
                    <th>Pais</th>
                    <th>{t("_REFERRALS._TABLE2_B")}</th>
                    <th>{t("_REFERRALS._TABLE2_C")}</th>
                  </tr>
                </thead>
                <tbody>
                  {teamsList.map((team, index) => (
                    <tr className="border-b2" key={index}>
                      <td>{abbreviateString(team?.address)}</td>
                      <td>{team?.username ? team?.username : "-"} </td>
                      <td>{team?.total_deposit ? team?.total_deposit : "-"} 🌊</td>
                      <td>{team?.country ? <Flag code={team?.country} height="16" fallback={<span>Unknown</span>} /> : "-"}</td>
                      <td>W{team?.package}</td>
                      <td>{team?.level ? team?.level : "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mainchart p-2 mb-2">
            <div className="row g-3">
              <div className="col-md-6">
                Mostrar <span className="font-semibold text-gray-900 dark:text-white mx-1">{(page === 0 ? 0 : page - 1) * limit + 1} - {Math.min(page * limit, total)}</span> de <span className="ml-1 font-semibold text-gray-900 dark:text-white">{total}</span>
              </div>
              <div className="d-flex flex-wrap align-items-center justify-content-lg-end gap-3 col-md-6">
                <ul className="nav nav-pills" id="pills-tab2" role="tablist">
                  {page !== 1 && (
                    <li className="nav-item" role="presentation">
                      <button className="nav-link rounded-3 py-1"
                        type="button" role="tab" onClick={() => setPage(page - 1)}>
                        Anterior
                      </button>
                    </li>
                  )}
                  <li className="nav-item" role="presentation">
                    <button className="nav-link rounded-3 py-1 active"
                      type="button" role="tab">
                      {page}
                    </button>
                  </li>
                  {page < Math.ceil(total / limit) && (
                    <li className="nav-item" role="presentation">
                      <button className="nav-link rounded-3 py-1"
                        type="button" role="tab" onClick={() => setPage(page + 1)}>
                        Siguiente
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      }
      {!isVisible &&
        <div>
          <h1 className="title-main">
            {t("_REFERRALS._INVITE_WIN")}
            <div className="title-main-button">
              <button className="darkblue" onClick={toggleVisibility}>{t("_BUTTONS._BACK")}</button>
            </div>
          </h1>
          <div className="d-lg-grid d-sm-flex grid-400-auto">
            <div className="blue-box w-400" style={{ marginTop: '0px' }}>
              <h4 className="text-white border-b-blue full-block fs-6 px-3">Invita a amigos y gana recompensas en ProfitWave</h4>
              <div className="d-flex w-100  full-block flex-column align-items-center">
                <p className="fs-14 my-4 text-center width70">Comparte el código a continuación o pídeles que lo ingresen durante el proceso de registro. <br />Serás recompensado cuando tus amigos cumplan con su tarea. </p>
                <div className="color-blue fake-input width70 d-flex justify-content-between p-2">{codeReferral} <img onClick={() => copyShared(codeReferral)} src={copy} alt="copy" className="m-1" /></div>
                <button className="social-login-button px-4 my-5 bg-blue br-6 " style={{ backgroundColor: '#00F0FF' }}>
                  Invitar
                </button>
              </div>
            </div>
            <img src={referrals} alt="referrals" style={{ width: '100%' }} />
          </div>
        </div>
      }
    </div>
  )
}
