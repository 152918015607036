import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../context/AppContex";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export default function Notifications() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { updateLoading, loading } = useContext(AppContext);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [data, setData] = useState([]);


    useEffect(() => {
        getNotifications();
        checkNotifications();
    }, []);


    const getNotifications = () => {
        updateLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND}/notifications?limit=50&page=0`, {
            method: "GET",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response?.status !== false) {
                    setData(response?.data?.items);
                }
                updateLoading(false);
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    };



    const checkNotifications = () => {
        updateLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND}/notifications`, {
            method: "post",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                updateLoading(false);
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    };


    const tiempoTranscurrido = (fecha) => {
        const fechaActual = new Date();
        const fechaNotificacion = new Date(fecha);
        const diferenciaTiempo = Math.abs(fechaActual - fechaNotificacion); // Diferencia en milisegundos
        const diferenciaHoras = Math.floor(diferenciaTiempo / (1000 * 60 * 60)); // Convertir a horas
        const diferenciaDias = Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24)); // Convertir a días

        if (diferenciaHoras < 24) {
            if (diferenciaHoras === 0) {
                return 'Hace menos de 1 hora';
            } else if (diferenciaHoras === 1) {
                return 'Hace 1 hora';
            } else {
                return `Hace ${diferenciaHoras} horas`;
            }
        } else if (diferenciaDias <= 10) {
            if (diferenciaDias === 1) {
                return 'Hace 1 día';
            } else {
                return `Hace ${diferenciaDias} días`;
            }
        } else {
            // Formatear solo a día, mes y año
            const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
            return fechaNotificacion.toLocaleDateString('es-ES', opciones);
        }
    }

    const toggleDropdown = (index) => {
        if (openDropdownIndex === index) {
            // Si el dropdown ya está abierto, cerrarlo
            setOpenDropdownIndex(null);
        } else {
            // Si está cerrado, abrirlo
            setOpenDropdownIndex(index);
        }
    };

    const goToLink = (linkgo) => {
        navigate(linkgo);
    };




    const deleteNotifications = (id) => {
        setOpenDropdownIndex(null)
        fetch(`${process.env.REACT_APP_BACKEND}/notifications-delete/${id}`, {
            method: "GET",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                getNotifications();
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <>
            <div className="row my-2 g-3 g-lg-4 pb-3 exchange">
                <div className="col-12">
                    <div className="mainchart px-3 px-md-4 py-3 py-lg-4">
                        <div className="d-flex align-items-center gap-2 mb-1">
                            <span className="material-symbols-outlined fw-light">
                                notifications_active
                            </span>
                            <span className="fw-bold text-white">{t("_HEADER._NOTIFICATIONS")}</span>
                        </div>
                        <p className="mb-0 pb-4 border-b">La página de Notificaciones muestra alertas y actualizaciones importantes del sistema en tiempo real. </p>
                        {data && data.map((data, index) => (
                            <div className="py-3 border-b " key={index}>
                                <div
                                    className="d-flex gap-3 gap-md-4 flex-wrap flex-sm-nowrap justify-content-md-between align-items-center position-relative">
                                    <div
                                        className="d-flex gap-2 align-items-start align-items-md-center flex-column flex-md-row notification-left">
                                        <div>
                                            <span className="medium"><span className="fw-bold text-white">{data?.title}</span></span>
                                            {data?.message && (<p className="mb-0">{data?.message}</p>)}
                                            {data?.value && (<p className="mb-0">Earnings: {data?.value} USDT</p>)}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2 flex-shrink-0 justify-content-between notification-right">
                                        <span>{tiempoTranscurrido(data?.created)}</span>
                                        <span className="material-symbols-outlined fw-semibold pointer" data-bs-toggle="dropdown" onClick={() => toggleDropdown(index)}>
                                            more_vert
                                        </span>
                                        {openDropdownIndex === index && (
                                            <ul className="dropdown-menu show" style={{ right: 0, left: 'auto' }}>
                                                <li><a className="dropdown-item text-black pointer" onClick={() => goToLink(data?.link)}>See more</a></li>
                                                <li><a className="dropdown-item text-danger pointer" onClick={() => deleteNotifications(data?.id)}>Delete</a></li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
