import React, { useState, useEffect, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import AppContext from "../context/AppContex";
import bitcoin from "../assets/crypto/bitcoin.png";
import etherium from "../assets/crypto/etherium.png";
import dogecoin from "../assets/crypto/dogecoin.png";

export default function Activity() {
  const { t } = useTranslation();
  const { updateLoading, loading } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);



  useEffect(() => {
    getActivitys();
  }, [page, limit]);

  const getActivitys = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/activity?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status !== false) {
          setData(response?.data?.items); // Guardamos los datos mapeados
          setTotal(response?.data?.total);
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const tiempoTranscurrido = (fecha) => {
    const fechaActual = new Date();
    const fechaNotificacion = new Date(fecha);
    const diferenciaTiempo = Math.abs(fechaActual - fechaNotificacion); // Diferencia en milisegundos

    const diferenciaMinutos = Math.floor(diferenciaTiempo / (1000 * 60)); // Convertir a minutos
    const diferenciaHoras = Math.floor(diferenciaTiempo / (1000 * 60 * 60)); // Convertir a horas
    const diferenciaDias = Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24)); // Convertir a días

    if (diferenciaMinutos < 60) {
      if (diferenciaMinutos < 1) {
        return 'Hace menos de 1 minuto';
      } else if (diferenciaMinutos === 1) {
        return 'Hace 1 minuto';
      } else if (diferenciaMinutos <= 5) {
        return `Hace ${diferenciaMinutos} minutos`;
      } else if (diferenciaMinutos <= 15) {
        return 'Hace 15 minutos';
      } else if (diferenciaMinutos <= 30) {
        return 'Hace 30 minutos';
      } else {
        return `Hace ${Math.floor(diferenciaMinutos / 60)} horas`;
      }
    } else if (diferenciaHoras < 24) {
      if (diferenciaHoras === 1) {
        return 'Hace 1 hora';
      } else {
        return `Hace ${diferenciaHoras} horas`;
      }
    } else {
      // Formatear solo a día, mes y año
      const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
      return fechaNotificacion.toLocaleDateString('es-ES', opciones);
    }
  }


  return (
    <div>
      <h1 className="title-main">{t("_ACTIVITIES._TITLE")}</h1>
      <div className="mainchart px-3 px-md-4 py-3 py-lg-4 ">
        <div>
          <h5 className="mb-0">{t("_ACTIVITIES._TABLE1")}</h5>
        </div>
        <div className="recent-contact pb-2 pt-3">
          <table>
            <thead>
              <tr className="border-b2">
                <th className="fw-bold">{t("_ACTIVITIES._TABLE1_A")}</th>
                <th className="fw-bold">{t("_ACTIVITIES._TABLE1_B")}</th>
                <th className="fw-bold">Level</th>
                <th className="fw-bold">{t("_ACTIVITIES._TABLE1_E")}</th>
                <th className="fw-bold">{t("_ACTIVITIES._TABLE1_C")}</th>

              </tr>
            </thead>
            <tbody>
              {data && data.map((data, index) => (
                <tr className="border-b2" key={index}>
                  <td>{data?.title}</td>
                  <td className="d-flex align-items-center gap-2">
                    {data?.value} USDT
                  </td>
                  <td>{data?.link ? data?.link : "-"}</td>
                  <td><span className={` ${data?.action != "7" ? "green-tag" : "red-tag"} `}> {` ${data?.action != "7" ? t("_STATUSES._COMPLETED") : "Perdida"}`} </span></td>
                  <td>{tiempoTranscurrido(data?.created)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="activity mt-4">
          <div className="row g-3">
            <div className="col-md-6">
              Mostrar <span className="font-semibold text-gray-900 dark:text-white mx-1">{(page === 0 ? 0 : page - 1) * limit + 1} - {Math.min(page * limit, total)}</span> de <span className="ml-1 font-semibold text-gray-900 dark:text-white">{total}</span>
            </div>
            <div className="d-flex flex-wrap align-items-center justify-content-lg-end gap-3 col-md-6">
              <ul className="nav nav-pills" id="pills-tab2" role="tablist">
                {page !== 1 && (
                  <li className="nav-item" role="presentation">
                    <button className="nav-link rounded-3 py-1"
                      type="button" role="tab" onClick={() => setPage(page - 1)}>
                      Anterior
                    </button>
                  </li>
                )}
                <li className="nav-item" role="presentation">
                  <button className="nav-link rounded-3 py-1 active"
                    type="button" role="tab">
                    {page}
                  </button>
                </li>
                {page < Math.ceil(total / limit) && (
                  <li className="nav-item" role="presentation">
                    <button className="nav-link rounded-3 py-1"
                      type="button" role="tab" onClick={() => setPage(page + 1)}>
                      Siguiente
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
