import { useState, useContext, useEffect, ReactNode } from "react";
import AllModal from "./components/AllModal";
import toast, { Toaster } from "react-hot-toast";
import AppContext from "./context/AppContex";
import Loading from "./components/Loading";
import "./styles/bootstrap_custom.css";
import "./styles/nice-select.css";
import "./styles/styles.css";
import "bootstrap-icons/font/bootstrap-icons.css";

type Props = {
  children?: ReactNode;
};
export default function Layout({ children }: Props) {
  const { state, loading } = useContext(AppContext);

  return (
    <>
      <main className={`${loading ? "modal-open" : ""}`}>
        {loading && <Loading />}
        <AllModal />
        {children}
      </main>
      <Toaster position="top-right" />
    </>
  );
}
