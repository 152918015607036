import React, { useState, useRef, useEffect, useContext } from "react";
import AppContext from "../context/AppContex";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import bitcoin from "../assets/crypto/bitcoin.png";
import etherium from "../assets/crypto/etherium.png";
import dogecoin from "../assets/crypto/dogecoin.png";
import tether from "../assets/crypto/tether.png";
import barras from "../assets/svg/barras.svg";
import Claim from "../components/Claim";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
} from "chart.js";
import { Line, Doughnut, Bar } from "react-chartjs-2";

export default function Home() {
  const navigate = useNavigate();
  const [activeSlide] = useState(true);
  const { t } = useTranslation();
  const { updateLoading, loading } = useContext(AppContext);
  const [packagesList, setPackagesList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [total, setTotal] = useState(0);
  const [labelsGraph, setLabelsGraph] = useState([]);
  const [arbitragesGraph, setArbitragesGraph] = useState([]);
  const [referralsGraph, setReferralsGraph] = useState([]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
  );


  useEffect(() => {
    getPackages();
  }, []);


  const getPackages = (data) => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/packages?limit=100`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status !== false) {
          setPackagesList(response?.data?.items)
          setUserData(response?.data?.user)
          const data = response?.data?.user;
          if (data && data.package_value_maximun && data.package_value_current) {
            const max = data.package_value_maximun;
            const current = data.package_value_current;
            const calculatedPercentage = (current / max) * 100;
            setPercentage(calculatedPercentage);
          }
        }
        updateLoading(false);
      })
      .catch((error) => {
        toast.error("No existe patrocinador, vuelve a intentarlo");
        updateLoading(false);
        console.log(error);
      });
  }
  const chart2 = useRef();

  const optionsLine = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    animation: {
      easing: "easeInOutQuad",
      duration: 1200,
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const dataLine = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    datasets: [
      {
        data: [20, 14, 22, 17, 27, 19, 20, 16],
        borderColor: "#0CAF60",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(10, 10, 10, 55);
          gradient.addColorStop(0, "#18975B");
          gradient.addColorStop(1, "#171F2A");
          return gradient;
        },
        borderWidth: 3,
        fill: true,
        lineTension: 0,
      },
    ],
  };

  useEffect(() => {
    getActivitys();
    getEarnings();
  }, [page, limit]);

  const getActivitys = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/activity?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status !== false) {
          setData(response?.data?.items); // Guardamos los datos mapeados
          setTotal(response?.data?.total);
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const getEarnings = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/earningsgraph?page=${page}&limit=1000`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status !== false) {
          const items = response?.data?.items;

          // Generar los últimos 27 días
          const labels = Array.from({ length: 27 }, (_, i) => {
            const date = new Date();
            date.setDate(date.getDate() - (26 - i));
            return date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
          });

          const labelsView = Array.from({ length: 27 }, (_, i) => {
            const date = new Date();
            date.setDate(date.getDate() - (26 - i));
            return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
          });

          setLabelsGraph(labelsView);
          // Inicializar arrays de datos para los datasets
          const arbitrajeData = new Array(27).fill(0);
          const referralsData = new Array(27).fill(0);

          // Rellenar los datos basados en los items recibidos
          items.forEach(item => {
            const index = labels.indexOf(item.date_created);
            if (index !== -1) {
              if (item.action === "1") {
                arbitrajeData[index] += parseFloat(item.value);
              } else if (item.action === "2") {
                referralsData[index] += parseFloat(item.value);
              }
            }
          });
          setArbitragesGraph(arbitrajeData);
          setReferralsGraph(referralsData);
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };


  const tiempoTranscurrido = (fecha) => {
    const fechaActual = new Date();
    const fechaNotificacion = new Date(fecha);
    const diferenciaTiempo = Math.abs(fechaActual - fechaNotificacion); // Diferencia en milisegundos

    const diferenciaMinutos = Math.floor(diferenciaTiempo / (1000 * 60)); // Convertir a minutos
    const diferenciaHoras = Math.floor(diferenciaTiempo / (1000 * 60 * 60)); // Convertir a horas
    const diferenciaDias = Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24)); // Convertir a días

    if (diferenciaMinutos < 60) {
      if (diferenciaMinutos < 1) {
        return 'Hace menos de 1 minuto';
      } else if (diferenciaMinutos === 1) {
        return 'Hace 1 minuto';
      } else if (diferenciaMinutos <= 5) {
        return `Hace ${diferenciaMinutos} minutos`;
      } else if (diferenciaMinutos <= 15) {
        return 'Hace 15 minutos';
      } else if (diferenciaMinutos <= 30) {
        return 'Hace 30 minutos';
      } else {
        return `Hace ${Math.floor(diferenciaMinutos / 60)} horas`;
      }
    } else if (diferenciaHoras < 24) {
      if (diferenciaHoras === 1) {
        return 'Hace 1 hora';
      } else {
        return `Hace ${diferenciaHoras} horas`;
      }
    } else {
      // Formatear solo a día, mes y año
      const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
      return fechaNotificacion.toLocaleDateString('es-ES', opciones);
    }
  }


  const options = {
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        position: 'top',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const data2 = {
    labels: labelsGraph,
    datasets: [
      {
        label: 'Arbitraje',
        data: arbitragesGraph,
        borderColor: "#00F0FF",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(10, 10, 10, 55);
          gradient.addColorStop(0, "#1A85C1");
          gradient.addColorStop(1, "#0E4666");
          return gradient;
        },
        borderWidth: 3,
        fill: true,
        lineTension: 0,
      },
      {
        label: 'Referrals',
        data: referralsGraph, // Datos para el segundo grupo
        borderColor: "#0CAF60",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(10, 10, 10, 55);
          gradient.addColorStop(0, "#18975B");
          gradient.addColorStop(1, "#171F2A");
          return gradient;
        },
        borderWidth: 3,
        fill: true,
        lineTension: 0,
      },
    ],
  };

  return (
    <>
      <div className="row my-2 g-3 g-lg-4">
        <h1 className="title-main">{t("_DASHBOARD._TITLE")}</h1>
        <div className="col-sm-12 col-lg-12">
          <div className="price-box">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p className="text-white mb-0">Guia de Inicio</p>
                <small>Completa todos los pasos y recibe hasta 30 Wavees de recompensa y un paquete gratuito de 20$</small>
              </div>
              <span className="material-symbols-outlined">quiz</span>
            </div>
            <div className="position-relative z-0">
              <Claim />

            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-8">
          <div className="packages-grid">
            <div className="full-column">
              <div className="grid">
                <div>
                  <p>{t("_PACKAGES._PACKAGES")}</p>
                  <h4>Packages Actives {packagesList.length}</h4>
                </div>
                <div>
                  <img className="" src={barras} alt="" />
                </div>
              </div>
              <div className='flex-between'>
                <p className="f14-w400 green">Start </p>
                <p className="f14-w400 green">Finish {userData?.package_value_maximun || 0} USDT</p>
              </div>
              <progress id="initialProgress" max="100" value={percentage} style={{ width: "100%" }} className="pink"></progress>
              <p className="f14-w400 ta-right">+{parseFloat(percentage.toFixed(2))}% - {userData?.package_value_current} USDT</p>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="price-box">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p className="text-white mb-0">Ganancias</p>
              </div>
              <img src={tether} alt="" />
            </div>

            <h4 className="fw-semibold text-white mb-0 text-center h3">{parseFloat(userData?.total_balance) + parseFloat(userData?.total_referred)} <small>USDT</small></h4>
            <h6 className="text-center">Level: W{userData?.package}</h6>
            <div className="chart">
              <Line options={optionsLine} data={dataLine} />
            </div>
          </div>
        </div>
      </div >
      <div className="row my-2 g-3 gx-lg-4">
        <div className="col-xl-12 col-xxl-12">
          <div className="mainchart px-3 px-md-4 py-3 py-lg-4">
            <div className="d-flex w-100 flex-wrap justify-content-between pb-4 bottom-border">
              <h4 className="mb-0">{t("_DASHBOARD._TABLE1")}</h4>
              <div className="d-flex align-items-center gap-3">
                <button className="outline-btn">View All</button>
              </div>
            </div>
            <div className="chart-container">
              <Bar options={options} data={data2} ref={chart2} />
            </div>
          </div>
        </div>
      </div>
      <div className="row my-2 g-3 gx-lg-4 pb-3">
        <div className="col-xl-7 col-xxl-9">
          <div className="mainchart px-3 px-md-4 py-3 py-lg-4 ">
            <div>
              <h5 className="mb-0">{t("_ACTIVITIES._TABLE1")}</h5>
            </div>
            <div className="recent-contact pb-2 pt-3">
              <table>
                <thead>
                  <tr className="border-b2">
                    <th className="fw-bold">{t("_ACTIVITIES._TABLE1_A")}</th>
                    <th className="fw-bold">{t("_ACTIVITIES._TABLE1_B")}</th>
                    <th className="fw-bold">Level</th>
                    <th className="fw-bold">{t("_ACTIVITIES._TABLE1_E")}</th>
                    <th className="fw-bold">{t("_ACTIVITIES._TABLE1_C")}</th>

                  </tr>
                </thead>
                <tbody>
                  {data && data.map((data, index) => (
                    <tr className="border-b2" key={index}>
                      <td>{data?.title}</td>
                      <td className="d-flex align-items-center gap-2">
                        {data?.value} USDT
                      </td>
                      <td>{data?.level ? data?.level : "-"}</td>
                      <td><span className={` ${data?.action != "7" ? "green-tag" : "red-tag"} `}> {` ${data?.action != "7" ? t("_STATUSES._COMPLETED") : "Perdida"}`} </span></td>
                      <td>{tiempoTranscurrido(data?.created)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-xxl-3">
          <div className="doughnut">
            <h5 className="text-whit mb-3">News</h5>
            <div className="coins-list">
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
