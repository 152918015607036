import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/svg/logo-large.svg";
import Dashboardicon from "../assets/dashboard_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  faUser,
  faMoneyBill,
  faBars,
  faTimes,
  faArrowRightFromBracket,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../context/AppContex";
import LanguageSelector from "./LanguageSelector";
import toast from "react-hot-toast";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setBalance, state } = useContext(AppContext);
  const location = useLocation().pathname;
  const [user, setUser] = useState();
  const [loading, setLoading] = useState();
  const [showMenu, setShowMenu] = useState(true);


  const currencyFormatter = (value) => {
    const formateado = value.toLocaleString("en", {
      style: "currency",
      currency: "USD",
    });
    return formateado;
  };

  const changeTypeBox = (type) => {
    props.changeTab(type);
  };

  const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("id");
    window.localStorage.removeItem("address");
    window.localStorage.removeItem("partner_id");
    window.location.replace("/?login=true");
  };

  const [activeItem, setActiveItem] = useState("dashboard");

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <>
      <div id="sidebar-wrapper" className="sidebar-wrapper">
        <div className="sidebar-heading">
          <img id="logo" src={Logo} alt="" style={{ width: '80%', margin: '0 auto' }} />
        </div>
        <nav className="sidebar mb-4">
          <ul className="nav flex-column" id="nav_accordion">
            <li className="nav-item">
              <Link
                to="/"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "dashboard" ? "active" : ""}`}
                onClick={() => handleItemClick("dashboard")}
              >
                {" "}
                <span className="material-symbols-outlined fw-lighte">
                  browse_activity
                </span>
                <span className="fw-semibold">{t("_SIDEBAR._DASHBOARD")}</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/packages"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "packages" ? "active" : ""}`}
                onClick={() => handleItemClick("packages")}
              >
                <span className="material-symbols-outlined">package_2</span>
                <span className="fw-semibold">{t("_SIDEBAR._PACKAGES")}</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/trading"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "trading" ? "active" : ""}`}
                onClick={() => handleItemClick("trading")}
              >
                <span className="material-symbols-outlined fw-lighter">
                  bar_chart_4_bars
                </span>
                <span className="fw-semibold">{t("_SIDEBAR._BOTTRADING")}</span>
              </Link>
            </li>
            {/* 
            <li className="nav-item">
              <Link
                to="/swap"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "swap" ? "active" : ""}`}
                onClick={() => handleItemClick("swap")}
              >
                <span className="material-symbols-outlined">
                  currency_exchange
                </span>
                <span className="fw-semibold">{t("_SIDEBAR._SWAP")}</span>
              </Link>
            </li>
*/}
            <li className="nav-item">
              <Link
                to="/airdrop"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "airdrop" ? "active" : ""}`}
                onClick={() => handleItemClick("airdrop")}
              >
                <span className="material-symbols-outlined">
                  featured_seasonal_and_gifts
                </span>
                <span className="fw-semibold">Airdrop</span>
              </Link>
            </li>


            <li className="nav-item">
              <Link
                to="/wallet"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "wallet" ? "active" : ""}`}
                onClick={() => handleItemClick("wallet")}
              >
                <span className="material-symbols-outlined">wallet</span>
                <span className="fw-semibold">{t("_SIDEBAR._WALLET")}</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/referrals"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "referrals" ? "active" : ""}`}
                onClick={() => handleItemClick("referrals")}
              >
                <span className="material-symbols-outlined">group</span>
                <span className="fw-semibold">{t("_SIDEBAR._REFERRALS")}</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/activity"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "activity" ? "active" : ""}`}
                onClick={() => handleItemClick("activity")}
              >
                <span className="material-symbols-outlined fw-lighter">
                  insights
                </span>
                <span className="fw-semibold">{t("_SIDEBAR._ACTIVITIES")}</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/settings"
                className={`nav-link d-flex gap-2 align-items-center ${activeItem === "settings" ? "active" : ""}`}
                onClick={() => handleItemClick("settings")}
              >
                <span className="material-symbols-outlined fw-lighter">
                  settings
                </span>
                <span className="fw-semibold">{t("_SIDEBAR._SETTINGS")}</span>
              </Link>
            </li>
          </ul>

          <div className="lang-select d-flex justify-content-center">
            <LanguageSelector />
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;