import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { formatInTimeZone } from 'date-fns-tz';
import { format } from 'date-fns';

const Countdown = ({ targetDate, dateServer }) => {
    const { t } = useTranslation();
    // Calculamos la fecha objetivo actualizada sumándole una hora a la fecha objetivo original
    const updatedTargetDate = new Date(new Date(targetDate).getTime() + 60 * 60 * 1000);

    // Función para calcular el tiempo restante
    const calculateTimeLeft = () => {
        const now = new Date();
        const timeZone = 'America/Lima';
        const limaTime = formatInTimeZone(now, timeZone, 'yyyy-MM-dd HH:mm:ssXXX');
        const serverTime = new Date(limaTime);
        const difference = +updatedTargetDate - +serverTime; // Calcula la diferencia en milisegundos entre la fecha objetivo y la fecha del servidor ajustada
        let timeLeft = {};

        if (difference > 0) { // Si la diferencia es mayor a cero, calculamos los días, horas, minutos y segundos restantes
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)), // Calcula los días restantes
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24), // Calcula las horas restantes
                minutes: Math.floor((difference / 1000 / 60) % 60), // Calcula los minutos restantes
                seconds: Math.floor((difference / 1000) % 60), // Calcula los segundos restantes
            };
        } else { // Si la diferencia es menor o igual a cero, el tiempo restante es cero
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
        return timeLeft; // Retorna el objeto con el tiempo restante
    };


    // Estado inicial del tiempo restante calculado
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // useEffect que actualiza el tiempo restante cada segundo
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft()); // Actualiza el estado con el nuevo tiempo restante
        }, 1000); // Intervalo de 1000 ms (1 segundo)

        return () => clearTimeout(timer); // Limpia el timeout para evitar fugas de memoria
    });

    return (
        <>
            <div class="d-flex flex-column flex-lg-row align-items-start justify-content-center gap-2 count-down-main my-2">
                <div class="timer w-100 w-lg-auto">
                    <div class="rounded bg-black bg-opacity-50  d-flex align-items-center justify-content-center flex-column gap-2 px-3">
                        <h3 class="countdown-element days font-weight-semibold display-4 text-white text-center">
                            {timeLeft.days}
                        </h3>
                        <h3 class="h5 text-white text-center w-100">{t("_COUNTDOWN._DAYS")}</h3>
                    </div>
                </div>
                <div class="timer w-100 w-lg-auto">
                    <div class="rounded bg-black bg-opacity-50 d-flex align-items-center justify-content-center flex-column gap-2 px-3">
                        <h3 class="countdown-element hours font-weight-semibold display-4 text-white text-center">
                            {timeLeft.hours}
                        </h3>
                        <h3 class="h5 text-white text-center w-100">{t("_COUNTDOWN._HOURS")}</h3>
                    </div>
                </div>
                <div class="timer w-100 w-lg-auto">
                    <div class="rounded bg-black bg-opacity-50 d-flex align-items-center justify-content-center flex-column gap-2 px-3">
                        <h3 class="countdown-element minutes font-weight-semibold display-4 text-white text-center">
                            {timeLeft.minutes}
                        </h3>
                        <h3 class="h5 text-white text-center w-100">{t("_COUNTDOWN._MINUTES")}</h3>
                    </div>
                </div>
                <div class="timer w-100 w-lg-auto">
                    <div class="rounded bg-black bg-opacity-50 d-flex align-items-center justify-content-center flex-column gap-2 px-3">
                        <h3 class="countdown-element seconds font-weight-semibold display-4 text-white text-center">
                            {timeLeft.seconds}
                        </h3>
                        <h3 class="h5 text-white text-center w-100">{t("_COUNTDOWN._SECONDS")}</h3>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Countdown;
