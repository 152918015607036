export const esp = {
  _BUTTONS: {
    _CONFIRM: "Confirmar",
    _BACK: "Regresar",
    _INVITE: "Invitar",
    _WITHDRAW: "Retirar",
    _DEPOSIT: "Depositar",
    _INVITE_WIN: "Invitar y ganar",
    _EDIT: "Editar",
  },
  _STATUSES: {
    _COMPLETED: "Completo",
    _REJECTED: "Rechazado",
    _PENDING: "Pendiente",
  },
  _SIDEBAR: {
    _DASHBOARD: "Tablero",
    _BOTTRADING: "Trading de bots",
    _PACKAGES: "Paquetes",
    _WALLET: "Mi billetera",
    _REFERRALS: "Referidos",
    _ACTIVITIES: "Actividades",
    _SETTINGS: "Configuración",
    _SWAP: "Intercambio",
  },
  _HEADER: {
    _SEARCH: "Buscar...",
    _NOTIFICATIONS: "Notificaciones",
    _WELCOME: "Bienvenid@",
  },
  _MODALRECHARGE: {
    _TITLE: "¡Fondos insuficientes!",
    _DESCRIPTION:
      "Parece que tu cuenta necesita un impulso. Ve a la página de Billetera para recargar y continuar tu emocionante viaje de inversión con Smartboxes.",
    _ACTION: "Recargar ahora",
  },
  _MODALBUY: {
    _TITLE: "Confirmar compra",
    _DESCRIPTION:
      "¿Estás seguro de que deseas proceder con esta compra? Verifica los detalles antes de confirmar.",
    _ACTION: "Confirmar compra",
    _SUCCESS: "Compra confirmada",
  },
  _BOARD: {
    _BACK: "Regresar",
    _BUY: "Comprar",
    _MY: "Mi caja",
    _PHONE: "¡Gira tu dispositivo, por favor!",
  },
  _WALLET: {
    _TITLE: "Billetera",
    _BALANCE: "Saldo total",
    _EXCHANGE: "Saldo de intercambio",
    _TEAMS: "Saldo de equipos",
    _TABLE1: "Precios de criptomonedas de hoy",
    _TABLE1_A: "Activo",
    _TABLE1_B: "En órdenes",
    _TABLE1_C: "Saldo disponible",
    _TABLE1_D: "Saldo total",
    _TABLE1_E: "Mercado de 24 horas",
    _TEXT1:
      "Recarga tu cuenta fácilmente para iniciar tu viaje de inversión con Smartboxes. Utiliza la dirección de billetera proporcionada a continuación o escanea el código QR. Asegúrate de seguir nuestras instrucciones para una transacción sin problemas.",
    _LI1: "Red de BNB:",
    _LIP1:
      "Realiza tu depósito exclusivamente a través de la red Binance Coin (BNB).",
    _LI2: "Monto y tarifa:",
    _LIP2:
      "Deposita el monto del paquete que deseas comprar más $1 adicional para cubrir la tarifa de la transacción. Por ejemplo, si quieres comprar un paquete de $50, deposita $51.",
    _LI3: "Responsabilidad del usuario:",
    _LIP3:
      "No somos responsables de las pérdidas de fondos debido a errores en la red BNB o direcciones de billetera incorrectas. Verifica cuidadosamente antes de confirmar la transacción.",
    _COPY: "Copiar billetera",
    _SHARED: "Dirección copiada al portapapeles correctamente.",
    _WITHDRAW_ADDRESS: "Dirección de retiro",
    _AMOUNT_RECEIVE: "Monto a recibir",
    _AMOUNT_DEDITED: "Monto a debitar",
    _PASSWORD_WITHDRAW: "Contraseña de retiro",
    _2FA_CODE: "Código 2FA",
    _WITHDRAWAL_FEE: "Comisión de retiro:",
    _NETWORK: "Red",
    _TABLE2: "Historial de retiros",
    _TABLE3: "Historial de depósitos",
    _TABLE23_A: "Orden",
    _TABLE23_B: "Monto",
    _TABLE23_C: "Fecha",
    _TABLE23_D: "Estado",
    _TITLE_QR_1: "Escanee el siguiente QR",
    _TITLE_QR_2: "o copie la dirección",
    _TEXT_QR: "depósito mínimo",
  },
  _REFERRALS: {
    _TITLE: "Referidos",
    _INVITE_WIN: "Invitar y ganar",
    _TABLE1: "Crecimiento personal",
    _TABLE1_A: "Ene",
    _TABLE1_B: "Feb",
    _TABLE1_C: "Mar",
    _TABLE1_D: "Abr",
    _TABLE1_E: "May",
    _TABLE1_F: "Jun",
    _TABLE1_G: "Jul",
    _TABLE1_H: "Ago",
    _TABLE1_I: "Sep",
    _TABLE1_J: "Oct",
    _TABLE1_K: "Nov",
    _TABLE1_L: "Dic",
    _TABLE2_A: "Tipo",
    _TABLE2_B: "Rango",
    _TABLE2_C: "Nivel",
    _INVITE_WIN_TITLE: "Invita amigos y gana recompensas en ProfitWave",
    _INVITE_WIN_TEXT1:
      "Comparte el código a continuación o haz que lo ingresen durante el proceso de registro.",
    _INVITE_WIN_TEXT2:
      "Serás recompensado cuando tus amigos completen su tarea.",
  },
  _ACTIVITIES: {
    _TITLE: "Actividades",
    _TABLE1: "Crecimiento global activo",
    _TABLE1_A: "Tipo",
    _TABLE1_B: "Activo",
    _TABLE1_C: "Fecha",
    _TABLE1_D: "Dirección IP",
    _TABLE1_E: "Lista de estados",
    _TABLE1_F: "Monto",
  },
  _PROFILE: {
    _TITLE: "Perfil",
    _USERNAME: "Nombre de usuario",
    _WALLETRECEIVE: "Billetera para recibir ganancias",
    _PASSWORD: "Contraseña",
    _ACTION: "Actualizar",
    _L1: "Red de BNB:",
    _L1P: "Realiza tu depósito exclusivamente a través de la red Binance Coin (BNB).",
    _L2: "Responsabilidad del usuario",
    _L2P: "No somos responsables de las pérdidas de fondos debido a errores en la red BNB o direcciones de billetera incorrectas. Verifica cuidadosamente antes de confirmar la transacción.",
    _TOASTSUCCESS: "Datos modificados exitosamente",
    _TOASTERROR: "Ocurrió un error, contacta al soporte",
  },
  _LOGIN: {
    _TITLE: "Inicio de sesión",
    _EMAIL: "Tu correo electrónico",
    _PASSWORD: "Tu contraseña · · · ·",
    _METAMASK: "Iniciar sesión con Metamask",
    _LOGIN: "Iniciar sesión",
    _FORGOTPASSWORD: "¿Olvidaste tu contraseña?",
  },
  _REGISTER: {
    _REGISTER: "Crear cuenta",
    _NAME: "Nombre",
    _LASTNAME: "Apellido",
    _COUNTRY: "País",
    _SPONSOR: "Patrocinador",
    _SEARCHSPONSOR: "Buscar Patrocinador",
    _EMAIL: "Tu correo electrónico",
    _CONFIRMEMAIL: "Confirmar correo electrónico",
    _PASSWORD: "Tu contraseña · · · ·",
    _CONFIRMPASSWORD: "Confirmar contraseña · · · ·",
    _TERMS: "términos y condiciones",
    _ACEPT: "Acepto ",
    _REGISTER: "Crear cuenta",
    _NOPARNETDESCRIPTION:
      "Necesitas un código de invitación de un socio para ingresar a nuestro sistema Profitwave, donde la tecnología blockchain y los contratos inteligentes multiplican tus inversiones hasta x8. ¡Contacta a alguien que te invite al negocio y descubre nuevas oportunidades financieras!",
    _ERROR:
      "El correo electrónico y el nombre de usuario deben ser únicos, prueba con otro.",
    _POLICY1:
      "Al presionar el botón 'Aceptar' a continuación, confirmas que has leído y aceptado nuestra",
    _POLICY2: "política de privacidad y términos de servicio.",
    _ALREDY: "Ya tengo una cuenta",
    _WITHDRAWAL:
      "El sistema inteligente procesará de inmediato cualquier donación recibida, enviándola automáticamente a una billetera BEP-20. No asumimos responsabilidad por errores; ingresa la dirección de la billetera BEP-20 con precisión. Tendrás la opción de modificarla más tarde si lo deseas.",
    _REQUIRED: "Este campo es obligatorio",
    _EMAIL: "Ingresa tu correo electrónico",
    _MINIUM: "Mínimo 6 caracteres y 1 número.",
    _MINIUMUSER: "Mínimo 4 caracteres",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "¿Olvidaste tu contraseña?",
    _EMAIL: "Tu correo electrónico",
    _BUTTON: "Enviar correo electrónico",
  },
  _DASHBOARD: {
    _TITLE: "Tablero",
    _TABLE1: "Crecimiento mensual global",
    _TABLE2: "Crecimiento global activo",
    _TABLE2_A: "Tipo",
    _TABLE2_B: "Activo",
    _TABLE2_C: "Fecha",
    _TABLE2_D: "Dirección IP",
    _TABLE2_E: "Lista de estados",
    _TABLE2_F: "Monto",
    _TABLE3: "Transacción reciente",
    _BALANCE: "Saldo total",
  },
  _BOTTRADING: {
    _BOTTRADING: "Trading de bots",
    _TABLE1: "Oportunidades de mercado",
    _TABLE1_A: "Tokens",
    _TABLE1_B: "Tokens",
    _TABLE1_C: "Ganancia",
    _TABLE2: "Aggregador de IA",
    _TABLE2_A: "Saldo",
    _TABLE2_B: "Token 1",
    _TABLE2_C: "Token 2",
    _TABLE2_D: "Detalles",
    _TABLE2_D_1: "Porcentaje de ganancia",
    _TABLE2_D_2: "Cantidad de ganancia",
    _TABLE2_D_3: "Total de operadores",
    _TABLE2_D_4: "Volumen total",
    _TABLE2_D_5: "Límite total de operaciones",
    _TABLE3: "Historial de operaciones",
    _TABLE3_A: "Fecha",
    _TABLE3_B: "TRX",
    _TABLE3_C: "Ganancia",
    _TABLE3_D: "Estado",
  },
  _PACKAGES: {
    _PACKAGES: "Paquetes de inversión",
    _PACKAGE: "Paquete",
    _BUTTON1: "Información",
    _BUTTON2: "Comprar",
    _PROFIT: "Ganancias de",
    _CAPITAL_A: "Incluyendo Capital",
    _CAPITAL_B: "hasta",
    _PLANS: "Planes simultáneos",
    _MYPACKAGES: "Mis paquetes",
    _MYPACKAGES_A: "Total generado",
  },
  _SETTINGS: {
    _TITLE: "Configuración",
    _TABLE1: "Editar perfil",
    _TABLE1_A: "Nombre",
    _TABLE1_B: "Apellido",
    _TABLE1_C: "País",
    _TABLE1_D: "Correo electrónico",
    _TABLE1_E: "Contraseña · · · ·",
    _TABLE1_F: "Confirmar contraseña · · · ·",
    _LEVEL: "Nivel",
    _TOTAL_BALANCE: "Saldo total",
  },
  _COUNTDOWN: {
    _TITLE: "Próximo corte:",
    _DAYS: "Días",
    _HOURS: "Horas",
    _MINUTES: "Minutos",
    _SECONDS: "Segundos",
  },
};
