import React, { useState, useContext } from "react";
import AppContext from "../context/AppContex";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RechargeModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { modalBackground, modalRecharge } = useContext(AppContext);
  const [tabForm, setTabForm] = useState(1);

  const closed = () => {
    modalRecharge(false);
    modalBackground(false);
  };

  const goToRecharge = () => {
    modalRecharge(false);
    modalBackground(false);
    navigate("/wallet");
  };
  return (
    <>
      <div
        className="modal-parent"
        style={{ cursor: "pointer", textAlign: "center" }}
      >
        <div className="modal-backdrop">
          <div className="modal" style={{ textAlign: "center" }}>
            <ul className="timeline two">
              <li className={`${tabForm === 1 ? "active-tl" : ""} `}>
                Pre Order
              </li>
              <li className={`${tabForm === 2 ? "active-tl" : ""} `}>Buy</li>
            </ul>
            <form>
              <h1>{t("_MODALRECHARGE._TITLE")}</h1>
              <p className="center">{t("_MODALRECHARGE._DESCRIPTION")}</p>
              <div className="actions two">
                <button onClick={() => goToRecharge()}>
                  <span>{t("_MODALRECHARGE._ACTION")}</span>
                </button>
                <button className="secondary" onClick={() => closed()}>
                  <span>{t("_BUTTON._CLOSED")}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RechargeModal;
