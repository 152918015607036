import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { eng } from "./en";
import { esp } from "./es";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: eng,
    },
    es: {
      translation: esp,
    },
  },
  lng: localStorage.getItem("lng") || "en",
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
