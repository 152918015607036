import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  const [activeSlide] = useState(true);

  return (
    <>
      <section className="error">
        <div className="inner d-flex flex-column align-items-center">
          <h3 className="text-white mt-3">Oops! Page not found</h3>
          <p className="large">
            Aenean id metus sed ante laoreet suscipit. Interdum et malesuada
            fames ac ante ipsum primis in faucibus. Donec erat lectus.
          </p>
          <Link to="/" className="primary-btn-lg">
            Go Back to Home Page →
          </Link>
        </div>
      </section>
    </>
  );
}
