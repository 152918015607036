import React from 'react'
import { useTranslation, Trans } from "react-i18next";
import profitwave from "../assets/svg/profitwave.svg";
import bgLines from "../assets/svg/bg-lines.svg";

export default function RecoverPassword() {
  const { t } = useTranslation();
  return (
    <>
      <section className="signin">
        <div className="signin-header bg-blue full-block row d-flex align-items-left">
          <img className="img-fluid" src={bgLines} alt="" />
        </div>
        <div className="signin-inner">
          <div className="row d-flex align-items-center g-3">
              <div className="form-wrapper d-flex flex-column align-items-center">
                  <img className="img-fluid" src={profitwave} alt="" />
                <form className="d-flex flex-column align-items-center">
                  <h4 className="text-center text-white border-b-blue full-block">{t("_RECOVERPASSWORD._RECOVERPASSWORD")}</h4>
                  <input
                    type="email"
                    id="password"
                    placeholder={t("_RECOVERPASSWORD._EMAIL")}
                    className="form-control form-control-1 form-control-2 py-2"
                  />
                  <div className=" fs-12 d-flex gap-2 flex-wrap justify-content-center buttom-group width70 pt-3">
                    <button type="submit" className="social-login-button d-flex justify-content-center align-items-center px-4" >
                      {t("_RECOVERPASSWORD._BUTTON")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </section>
    </>
  )
}
