import { http, createConfig } from 'wagmi'
import { bsc, base, mainnet, bscTestnet } from "wagmi/chains";
import { reconnect } from '@wagmi/core'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

const metadata = {
    name: 'Wavees',
    description: 'Wavees a company dedicated to operating in the financial market using an innovative approach and a liquidity pool from partners',
    url: 'https://wavees.io/', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}
const projectId = 'bcbdb3e391c0b0d45a00fe641abc4106'


export const config = defaultWagmiConfig({
    chains: [bscTestnet],
    projectId,
    metadata,
})
/*
export const config = createConfig({
    chains: [mainnet, base, bsc],
    connectors: [
        injected(),
        walletConnect({ projectId }),
        metaMask(),
        safe(),
    ],
    transports: {
        [mainnet.id]: http(),
        [base.id]: http(),
        [bsc.id]: http(),
    },
})
reconnect(config)*/