import { useState, useContext, useEffect, ReactNode } from "react";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { useNavigate } from "react-router-dom";
import AppContext from "./context/AppContex";
import { useAccount, useDisconnect } from "wagmi";
import NetworkAndAccountListener from "./web3/NetworkAndAccountListener";
type Props = {
  children?: ReactNode;
};
export default function LayoutAdmin({ children }: Props) {
  const { menu } = useContext(AppContext);
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useAccount();
  const [firstAddress, setFirstAddress] = useState<string | null>(null);
  const [initialSet, setInitialSet] = useState(false); // Controlar el primer set

  useEffect(() => {
    /*
    if (!initialSet && address && isConnected) {
      // Guardar la primera address si no ha sido guardada antes y está conectado
      if (address != null) {
        setFirstAddress(address);
        setInitialSet(true); // Marcar que ya se ha guardado la primera vez
      }
    }
    if (initialSet && (address != firstAddress || !isConnected)) {
      alert("Change Wallet Detect, Disconnect Automatic");
      disconnect();
      localStorage.clear();
      navigate(`/signin`);
    }*/
    if (!address) {
      disconnect();
      localStorage.clear();
      navigate(`/signin`);
    }
  }, [address]);

  return (
    <>
      <div className={`d-flex wrapper ${menu ? "toggled" : ""} `} id="wrapper">
        <Sidebar />
        <section id="page-content-wrapper" className="page-content-wrapper">
          <Header />
          <div className="container-fluid main-content px-2 px-lg-5">
            {children}
          </div>
        </section>
      </div>
    </>
  );
}
