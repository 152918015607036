import React, { useState, useRef, useEffect, useContext } from "react";
import AppContext from "../context/AppContex";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import {
    BaseError,
    useWaitForTransactionReceipt,
    useWriteContract,
} from "wagmi";
import { abi } from "../abi/pool";
import { useAccount } from "wagmi";
import { formatInTimeZone } from 'date-fns-tz';

export default function ArbitrageSubmit({ targetDate, dateServer }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { address } = useAccount();
    const [amount, setAmount] = useState();
    const { updateLoading, loading } = useContext(AppContext);
    const { data: hash, error, isPending, writeContract } = useWriteContract();
    const [isArbitrageDone, setIsArbitrageDone] = useState(false);
    const REACT_APP_CONTRACT = (process.env.REACT_APP_CONTRACT ?? ""); // Dirección 
    const updatedTargetDate = new Date(new Date(targetDate).getTime() + 60 * 60 * 1000);

    useEffect(() => {
        const updatedTargetDate = new Date(new Date(targetDate).getTime() + 60 * 60 * 1000);
        const now = new Date();
        const timeZone = 'America/Lima';
        const limaTime = formatInTimeZone(now, timeZone, 'yyyy-MM-dd HH:mm:ssXXX');
        const serverTime = new Date(limaTime);
        const difference = +updatedTargetDate - +serverTime; // Calcula la diferencia en milisegundos entre la fecha objetivo y la fecha del servidor ajustada
        if (difference > 0) { // Si la diferencia es mayor a cero, calculamos los días, horas, minutos y segundos restantes
            setIsArbitrageDone(true);
        } else { // Si la diferencia es menor o igual a cero, el tiempo restante es cero
            setIsArbitrageDone(false);
        }
    }, [targetDate]);


    const signerArbitrage = () => {
        updateLoading(true);
        fetch(`${process.env.REACT_APP_BACKEND}/signer-arbitrage`, {
            method: "POST",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                arbitrageWrite(JSON.parse(response.signer))
                setAmount(response.amount);
            })
            .catch((error) => {
                updateLoading(false);
                toast.error("Vuelve a intentarlo");
            });
    };


    async function arbitrageWrite(hash) {
        console.log(hash);
        writeContract({
            address: REACT_APP_CONTRACT, //address contrato
            abi,
            functionName: "executeFlashLoan",
            args: [address, hash],
        });
    }

    const { isLoading: isConfirming, isSuccess: isConfirmed } =
        useWaitForTransactionReceipt({
            hash,
        });

    useEffect(() => {
        if (hash) {
            console.log(hash);
            completedArbitrage(hash)
        }
    }, [hash]);

    useEffect(() => {
        if (isConfirmed) {
            console.log("Pucharse Succesfull");
        }
    }, [isConfirmed]);

    useEffect(() => {
        if (error) {
            toast.error((error).shortMessage || error.message);
            updateLoading(false);
        }
    }, [error]);



    const completedArbitrage = (hash) => {
        const formData = new FormData();
        formData.append("amount", amount);
        formData.append("trx", hash);
        fetch(`${process.env.REACT_APP_BACKEND}/completed-arbitrage`, {
            method: "POST",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response?.status === false) {
                    toast.error("El Arbitraje no se completo, vuelve a intentarlo.");
                } else {
                    toast.success("Arbitraje completado con exito");
                }
                updateLoading(false);
            })
            .catch((error) => {
                updateLoading(false);
                console.log(error);
                toast.error("Vuelve a intentarlo");
            });
    };


    return (
        <>
            <div className=" fs-5 d-flex gap-2 flex-wrap justify-content-center">
                <button
                    className={`button-generic p-2 mb-4 full-block d-flex align-items-center justify-content-center ${isArbitrageDone ? 'btn-secondary' : 'lightblue'}`}
                    onClick={!isArbitrageDone ? () => signerArbitrage() : null}
                    disabled={isArbitrageDone}
                >
                    {isArbitrageDone ? 'Ganancia ya retirada' : 'Retirar Ganancia'}
                </button>
            </div>

        </>
    );
}
