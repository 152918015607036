import React, { useState, useRef, useEffect, useContext } from "react";
import dogecoin from "../assets/crypto/dogecoin.png";
import AppContext from "../context/AppContex";
import { useTranslation, Trans } from "react-i18next";
import { useAccount } from "wagmi";

export default function ArbitrageList() {
    const { t } = useTranslation();
    const [opportunities, setOpportunities] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);



    const removeCurrency = (str) => {
        return str.replace(/(USDT|USD|USDC)$/g, '').trim();
    };

    const fetchOpportunities = async () => {
        try {
            setLoading(true);
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 120000); // 2 minutos

            const response = await fetch('https://gzxmt3gshd.execute-api.us-west-1.amazonaws.com/default/tools-arbitrage', {
                signal: controller.signal
            });


            if (!response.ok) {
                updateLoading(false);
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            clearTimeout(timeoutId);
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            if (error.name === 'AbortError') {
                console.error('Fetch timed out:', error);
            } else {
                console.error('Error fetching arbitrage opportunities:', error);
            }
            return [];
        }
    };
    useEffect(() => {
        const getOpportunities = async () => {
            const data = await fetchOpportunities();

            // Crear un nuevo elemento `long` en cada objeto basado en el valor de `crypto`
            const processedData = data.map(opportunity => ({
                ...opportunity,
                long: removeCurrency(opportunity.crypto), // Nuevo elemento `long`
            }));

            // Barajar el array y seleccionar los primeros 10 elementos
            const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);
            const randomOpportunities = shuffleArray(processedData).slice(0, 7);

            setOpportunities(randomOpportunities);
        };

        getOpportunities();
        const intervalId = setInterval(getOpportunities, 8000); // 21 segundos

        // Cleanup interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    function openInNewTab(long) {
        const newWindow = window.open("https://coinmarketcap.com/es/currencies/" + long + "/#Markets", '_blank');
        if (newWindow) {
            newWindow.focus(); // Asegura que la nueva pestaña tenga el foco
        } else {
            alert('No se pudo abrir la nueva pestaña. Asegúrate de que no estén bloqueadas las ventanas emergentes.');
        }
    }

    const getTokenInfo = async (symbol) => {
        const url = `https://pro-api.coinmarketcap.com/v2/cryptocurrency/info?symbol=${symbol.toUpperCase()}`;
        const headers = {
            "X-CMC_PRO_API_KEY": "20206b0e-8150-41be-824a-71d4e925330b",
        };
        try {
            const response = await fetch(url, { headers });
            const data = await response.json();

            if (data.status.error_code === 0) {
                const tokenInfo = data.data[symbol.toUpperCase()];
                console.log(tokenInfo.name);
                console.log(tokenInfo.urls.website[0]);
            } else {
                console.log('No se consiguio información')
            }
        } catch (err) {
            console.log('Error a buscar solicitud')
        }
    };



    return (
        <>
            <div className="mainchart px-3 px-md-4 py-3 py-lg-4 text-white gap-b-mobile">
                <div>
                    <h5 className="mb-0">{t("_BOTTRADING._TABLE1")}</h5>
                </div>
                <div className="recent-contact pb-2 pt-3">
                    <table>
                        <thead>
                            <tr className="border-b2">
                                <th>{t("_BOTTRADING._TABLE1_A")}</th>
                                <th>EXCHANGES</th>
                                <th>{t("_BOTTRADING._TABLE1_C")}</th>
                                <></>
                            </tr>
                        </thead>
                        <tbody>
                            {opportunities.length > 0 ? (
                                opportunities.map((opportunity, index) => (
                                    <tr className="border-b2" key={index}>
                                        <td>{opportunity.crypto}</td>
                                        <td>{opportunity.buyExchange} - {opportunity.sellExchange}</td>
                                        <td
                                            className={
                                                opportunity.percentageDifference > 0
                                                    ? "text-green"
                                                    : opportunity.percentageDifference < 0
                                                        ? "text-red"
                                                        : "text-gray"
                                            }
                                        >
                                            {opportunity.percentageDifference.toFixed(2)}%
                                        </td>
                                        <td>
                                            <span className="material-symbols-outlined" onClick={() => openInNewTab(opportunity.long)}>
                                                link
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <h1>Loading...</h1>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
