import React, { useState, useEffect, useContext } from "react";
import AppContext from "../context/AppContex";
import bgLines from "../assets/svg/bg-lines.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import profitwave from "../assets/svg/profitwave.svg";
import queryString from "query-string";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../components/LanguageSelector";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { useAccount, useSignMessage } from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'

export default function Login() {
  const navigate = useNavigate();
  const [activeSlide] = useState(true);
  const { t } = useTranslation();
  const [loginType, setLoginType] = useState(false);
  const [message, setMessage] = useState('Please sign this message to log in.');
  const [isVerified, setIsVerified] = useState(false);
  const { isConnected, address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const { open, close } = useWeb3Modal()
  const { modalBackground, modalRegister, modalLogin } = useContext(AppContext);
  const [checkTerms, setCheckTerms] = useState(true);


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (isConnected && token) {
      navigate("/");
    }
    if (isConnected && !token) {
      handleSignMessage()
    }
  }, [isConnected]);

  const handleSignMessage = async () => {
    if (!isConnected) {
      toast.error("Please connect your wallet first");
      return;
    }
    try {
      const signedMessage = await signMessageAsync({ message });
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address,
          signedMessage,
        }),
      });
      const data = await response.json();
      if (data.status === "OK") {
        console.log(data)
        setIsVerified(true);
        localStorage.setItem("token", data?.token);
        localStorage.setItem("address", data?.user?.address);
        localStorage.setItem("id", data?.user?.id);
        localStorage.setItem("user", JSON.stringify(data?.user));
        localStorage.setItem("referral", data?.user?.referred_my);
        localStorage.setItem("balance", data?.user?.balance);
        localStorage.setItem("balance_wavees", data?.user?.balance_wavees);
        toast.success("Login Success");
        navigate("/");
      } else {
        toast.error("You need a reference code to enter the system.");
      }
    } catch (error) {
      toast.error("An error occurred during signing");
    }
  };

  const goToLogin = async () => {
    if (!isConnected) {
      open();
    } else {
      handleSignMessage()
    }
  };

  const goToRegister = () => {
    navigate("/signup");
  }

  return (
    <>
      <section className="signin">
        <div className="signin-header bg-blue full-block row d-flex align-items-left">
          <img className="img-fluid" src={bgLines} alt="" />
        </div>
        <div className="signin-inner w-400">
          <div className="row d-flex align-items-center g-3">
            <div className="form-wrapper d-flex flex-column align-items-center">
              <img className="img-fluid" src={profitwave} alt="" />
              <form className="d-flex flex-column align-items-center">
                <h4 className="text-center text-white border-b-blue full-block">{t("_LOGIN._TITLE")}</h4>
              </form>
              <br />
              <div className=" fs-12 d-flex gap-2 flex-wrap justify-content-center buttom-group width70 pt-3 ">
                <button className="social-login-button d-flex justify-content-center align-items-center px-4" onClick={() => goToLogin()}>
                  {t("_LOGIN._LOGIN")}
                </button>
              </div>
              {/*  <p className="mt-2 pe-auto pointer" onClick={() => goToRegister()}>Create account</p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}