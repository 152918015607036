import React from 'react'
import { useTranslation } from "react-i18next";
import trc from "../assets/svg/trc.svg";
import grafic from "../assets/svg/grafic.svg";
import { Link } from "react-router-dom";

export default function Withdrawal() {
  const { t } = useTranslation();
  return (
    <>
      <section className="container-full bg1">
        <div className="container" style={{color: 'black'}}>
        <h1 className="title-main">
          {t("_BUTTONS._WITHDRAW")}
          <div className="title-main-button">
              <Link
                to="/wallet"
                className=""
              >
                <button className="purple">
                  {t("_BUTTONS._BACK")}
                </button>
              </Link>
          </div>
        </h1>
        <div>
          <div className="d-grid grid-auto-200 gap-b-mobile">
            <div className="signin retiro">
              <div className="container-blue signin-inner retiro w-100 mt-0">
                <div className="row d-flex align-items-center g-3">
                    <div className="form-wrapper retiro d-flex flex-column align-items-center">
                      <form className="d-lg-grid d-sm-flex p-3" style={{gridTemplateColumns: "calc(50% - 5px) calc(50% - 5px)", gap: "20px"}}>
                        <div className="w-100 fs-12">
                          <label htmlFor="withdrawalAddress">{t("_WALLET._WITHDRAW_ADDRESS")}</label>
                          <input
                            type="text"
                            id="withdrawalAddress"
                            className="form-control form-control-1 w-100 gap-b-mobile" 
                          />
                        </div>
                        <div className="w-100 fs-12">
                          <label htmlFor="amountToReceive">{t("_WALLET._AMOUNT_RECEIVE")}</label>
                          <input
                            type="text"
                            id="amountToReceive"
                            className="form-control form-control-1 w-100 gap-b-mobile" 
                            placeholder="$0,00"
                          />
                        </div>
                        <div className="w-100 fs-12">
                          <label htmlFor="amountToBeDebited">{t("_WALLET._AMOUNT_DEDITED")}</label>
                          <input
                            type="text"
                            id="amountToBeDebited"
                            className="form-control form-control-1 w-100 gap-b-mobile" 
                            placeholder="$0,00"
                          />
                        </div>
                        <div className="w-100 fs-12">
                          <label htmlFor="passwordWithdrawal">{t("_WALLET._PASSWORD_WITHDRAW")}</label>
                          <input
                            type="text"
                            id="passwordWithdrawal"
                            className="form-control form-control-1 w-100 gap-b-mobile" 
                            placeholder="· · · · · · · ·"
                          />
                        </div>
                        <div className="w-100 fs-12">
                          <label htmlFor="2faCode">{t("_WALLET._2FA_CODE")}</label>
                          <input
                            type="text"
                            id="2faCode"
                            className="form-control form-control-1 w-100 gap-b-mobile" 
                            placeholder="· · · · · · · ·"
                          />
                        </div>
                        <div>
                          <button type="submit" className="social-login-button d-flex justify-content-center align-items-center py-0" >
                          {t("_BUTTONS._WITHDRAW")}
                          </button>
                        </div>
                        <p className="fw-light fs-14" style={{color: '#00F0FF'}}>{t("_WALLET._WITHDRAWAL_FEE")} 2USDT</p>
                      </form>
                    </div>
                  </div>
              </div>
            </div>
            <div className="grid purple br-6 d-flex justify-content-between flex-column align-items-center position-relative w-200 p-10-10-90" style={{paddingBottom: '0px'}}>
              <h6 className="fs-14">{t("_WALLET._NETWORK")}: TRC-20 - USDT</h6>
              <img src={trc} alt="trc" className="width-img-mobile " style={{width: '90%', marginRight: '0px'}}/>
              <img style={{width: 'calc(100% + 20px)', marginRight: '0px'}}src={grafic} alt="" />
            </div>
          </div>
            <div className="mainchart px-3 px-md-4 py-3 py-lg-4 text-white mt-lg-5">
              <div>
                <h5 className="mb-0">{t("_WALLET._TABLE2")}</h5>
              </div>
              <div className="recent-contact pb-2 pt-3">
                <table>
                  <thead>
                    <tr className="border-b2">
                      <th>{t("_WALLET._TABLE23_A")}</th>
                      <th>{t("_WALLET._TABLE23_B")}</th>
                      <th>{t("_WALLET._TABLE23_C")}</th>
                      <th>{t("_WALLET._TABLE23_D")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b2">
                      <td>Orden: #148</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td><span className="green-tag">{t("_STATUSES._COMPLETED")}</span></td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #432</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td><span className="yellow-tag">{t("_STATUSES._PENDING")}</span></td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #765</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td><span className="red-tag">{t("_STATUSES._REJECTED")}</span></td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #148</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td><span className="green-tag">{t("_STATUSES._COMPLETED")}</span></td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #432</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td><span className="yellow-tag">{t("_STATUSES._PENDING")}</span></td>
                    </tr>
                    <tr className="border-b2">
                      <td>Orden: #765</td>
                      <td>$247.18</td>
                      <td>2023-07-07 18:06:24</td>
                      <td><span className="red-tag">{t("_STATUSES._REJECTED")}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
        </div>
      </section>
    </>
  )
}
