import React, { useState, useContext } from "react";
import AppContext from "../context/AppContex";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const BuyModal = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { modalBackground, modalBuy, state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [eye, setEyed] = useState(false);
  const [errorsM, setErrorsM] = useState("");
  const [tabForm, setTabForm] = useState(1);
  const { handleSubmit, control, errors, formState, getValues } = useForm({
    mode: "onChange",
  });

  const closed = () => {
    modalBuy(false);
    modalBackground(false);
  };

  const goToPucharse = () => {
    const boxInformation = state.box;
    setLoading(true);
    const formData = new FormData();
    formData.append("position", boxInformation?.level);
    formData.append("box_id", boxInformation?.boxId);
    formData.append("level", boxInformation?.position);
    fetch(`${process.env.REACT_APP_BACKEND}/box-buy`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status === false) {
          setLoading(false);
          toast.error("Refresh Website");
          toast.error(response.msg);
        } else {
          toast.success(t("_MODALBUY._SUCCESS"));
          setLoading(false);
          modalBuy(false);
          modalBackground(false);
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Vuelve a intentarlo");
        modalBuy(false);
        modalBackground(false);
      });
  };
  return (
    <>
      <div className="modal fade show d-block" tabIndex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog"
        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="fullscreenModalLabel">Fullscreen Modal</h5>
              <button type="button" className="btn-close" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              This is a fullscreen modal using Bootstrap v5.3.0-alpha2.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyModal;
