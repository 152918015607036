import React, { useState, useRef, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import profile from "../assets/svg/profile.svg";
import grafic from "../assets/svg/grafic.svg";
import AppContext from "../context/AppContex";
import Flag from 'react-world-flags'

import toast from "react-hot-toast";
export default function Settings() {
  const { updateLoading, loading } = useContext(AppContext);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  // React Hook Form setup
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();


  const countries = [
    { name: "Afghanistan", flag: <Flag code="af" height="16" />, abbv: "af" },
    { name: "Albania", flag: <Flag code="al" height="16" />, abbv: "al" },
    { name: "Algeria", flag: <Flag code="dz" height="16" />, abbv: "dz" },
    { name: "Andorra", flag: <Flag code="ad" height="16" />, abbv: "ad" },
    { name: "Angola", flag: <Flag code="ao" height="16" />, abbv: "ao" },
    { name: "Antigua and Barbuda", flag: <Flag code="ag" height="16" />, abbv: "ag" },
    { name: "Argentina", flag: <Flag code="ar" height="16" />, abbv: "ar" },
    { name: "Armenia", flag: <Flag code="am" height="16" />, abbv: "am" },
    { name: "Australia", flag: <Flag code="au" height="16" />, abbv: "au" },
    { name: "Austria", flag: <Flag code="at" height="16" />, abbv: "at" },
    { name: "Azerbaijan", flag: <Flag code="az" height="16" />, abbv: "az" },
    { name: "Bahamas", flag: <Flag code="bs" height="16" />, abbv: "bs" },
    { name: "Bahrain", flag: <Flag code="bh" height="16" />, abbv: "bh" },
    { name: "Bangladesh", flag: <Flag code="bd" height="16" />, abbv: "bd" },
    { name: "Barbados", flag: <Flag code="bb" height="16" />, abbv: "bb" },
    { name: "Belarus", flag: <Flag code="by" height="16" />, abbv: "by" },
    { name: "Belgium", flag: <Flag code="be" height="16" />, abbv: "be" },
    { name: "Belize", flag: <Flag code="bz" height="16" />, abbv: "bz" },
    { name: "Benin", flag: <Flag code="bj" height="16" />, abbv: "bj" },
    { name: "Bhutan", flag: <Flag code="bt" height="16" />, abbv: "bt" },
    { name: "Bolivia", flag: <Flag code="bo" height="16" />, abbv: "bo" },
    { name: "Bosnia and Herzegovina", flag: <Flag code="ba" height="16" />, abbv: "ba" },
    { name: "Botswana", flag: <Flag code="bw" height="16" />, abbv: "bw" },
    { name: "Brazil", flag: <Flag code="br" height="16" />, abbv: "br" },
    { name: "Brunei", flag: <Flag code="bn" height="16" />, abbv: "bn" },
    { name: "Bulgaria", flag: <Flag code="bg" height="16" />, abbv: "bg" },
    { name: "Burkina Faso", flag: <Flag code="bf" height="16" />, abbv: "bf" },
    { name: "Burundi", flag: <Flag code="bi" height="16" />, abbv: "bi" },
    { name: "Cabo Verde", flag: <Flag code="cv" height="16" />, abbv: "cv" },
    { name: "Cambodia", flag: <Flag code="kh" height="16" />, abbv: "kh" },
    { name: "Cameroon", flag: <Flag code="cm" height="16" />, abbv: "cm" },
    { name: "Canada", flag: <Flag code="ca" height="16" />, abbv: "ca" },
    { name: "Central African Republic", flag: <Flag code="cf" height="16" />, abbv: "cf" },
    { name: "Chad", flag: <Flag code="td" height="16" />, abbv: "td" },
    { name: "Chile", flag: <Flag code="cl" height="16" />, abbv: "cl" },
    { name: "China", flag: <Flag code="cn" height="16" />, abbv: "cn" },
    { name: "Colombia", flag: <Flag code="co" height="16" />, abbv: "co" },
    { name: "Comoros", flag: <Flag code="km" height="16" />, abbv: "km" },
    { name: "Congo", flag: <Flag code="cg" height="16" />, abbv: "cg" },
    { name: "Costa Rica", flag: <Flag code="cr" height="16" />, abbv: "cr" },
    { name: "Croatia", flag: <Flag code="hr" height="16" />, abbv: "hr" },
    { name: "Cuba", flag: <Flag code="cu" height="16" />, abbv: "cu" },
    { name: "Cyprus", flag: <Flag code="cy" height="16" />, abbv: "cy" },
    { name: "Czech Republic", flag: <Flag code="cz" height="16" />, abbv: "cz" },
    { name: "Denmark", flag: <Flag code="dk" height="16" />, abbv: "dk" },
    { name: "Djibouti", flag: <Flag code="dj" height="16" />, abbv: "dj" },
    { name: "Dominica", flag: <Flag code="dm" height="16" />, abbv: "dm" },
    { name: "Dominican Republic", flag: <Flag code="do" height="16" />, abbv: "do" },
    { name: "Ecuador", flag: <Flag code="ec" height="16" />, abbv: "ec" },
    { name: "Egypt", flag: <Flag code="eg" height="16" />, abbv: "eg" },
    { name: "El Salvador", flag: <Flag code="sv" height="16" />, abbv: "sv" },
    { name: "Equatorial Guinea", flag: <Flag code="gq" height="16" />, abbv: "gq" },
    { name: "Eritrea", flag: <Flag code="er" height="16" />, abbv: "er" },
    { name: "Estonia", flag: <Flag code="ee" height="16" />, abbv: "ee" },
    { name: "Eswatini", flag: <Flag code="sz" height="16" />, abbv: "sz" },
    { name: "Ethiopia", flag: <Flag code="et" height="16" />, abbv: "et" },
    { name: "Fiji", flag: <Flag code="fj" height="16" />, abbv: "fj" },
    { name: "Finland", flag: <Flag code="fi" height="16" />, abbv: "fi" },
    { name: "France", flag: <Flag code="fr" height="16" />, abbv: "fr" },
    { name: "Gabon", flag: <Flag code="ga" height="16" />, abbv: "ga" },
    { name: "Gambia", flag: <Flag code="gm" height="16" />, abbv: "gm" },
    { name: "Georgia", flag: <Flag code="ge" height="16" />, abbv: "ge" },
    { name: "Germany", flag: <Flag code="de" height="16" />, abbv: "de" },
    { name: "Ghana", flag: <Flag code="gh" height="16" />, abbv: "gh" },
    { name: "Greece", flag: <Flag code="gr" height="16" />, abbv: "gr" },
    { name: "Grenada", flag: <Flag code="gd" height="16" />, abbv: "gd" },
    { name: "Guatemala", flag: <Flag code="gt" height="16" />, abbv: "gt" },
    { name: "Guinea", flag: <Flag code="gn" height="16" />, abbv: "gn" },
    { name: "Guinea-Bissau", flag: <Flag code="gw" height="16" />, abbv: "gw" },
    { name: "Guyana", flag: <Flag code="gy" height="16" />, abbv: "gy" },
    { name: "Haiti", flag: <Flag code="ht" height="16" />, abbv: "ht" },
    { name: "Honduras", flag: <Flag code="hn" height="16" />, abbv: "hn" },
    { name: "Hungary", flag: <Flag code="hu" height="16" />, abbv: "hu" },
    { name: "Iceland", flag: <Flag code="is" height="16" />, abbv: "is" },
    { name: "India", flag: <Flag code="in" height="16" />, abbv: "in" },
    { name: "Indonesia", flag: <Flag code="id" height="16" />, abbv: "id" },
    { name: "Iran", flag: <Flag code="ir" height="16" />, abbv: "ir" },
    { name: "Iraq", flag: <Flag code="iq" height="16" />, abbv: "iq" },
    { name: "Ireland", flag: <Flag code="ie" height="16" />, abbv: "ie" },
    { name: "Israel", flag: <Flag code="il" height="16" />, abbv: "il" },
    { name: "Italy", flag: <Flag code="it" height="16" />, abbv: "it" },
    { name: "Ivory Coast", flag: <Flag code="ci" height="16" />, abbv: "ci" },
    { name: "Jamaica", flag: <Flag code="jm" height="16" />, abbv: "jm" },
    { name: "Japan", flag: <Flag code="jp" height="16" />, abbv: "jp" },
    { name: "Jordan", flag: <Flag code="jo" height="16" />, abbv: "jo" },
    { name: "Kazakhstan", flag: <Flag code="kz" height="16" />, abbv: "kz" },
    { name: "Kenya", flag: <Flag code="ke" height="16" />, abbv: "ke" },
    { name: "Kiribati", flag: <Flag code="ki" height="16" />, abbv: "ki" },
    { name: "Kuwait", flag: <Flag code="kw" height="16" />, abbv: "kw" },
    { name: "Kyrgyzstan", flag: <Flag code="kg" height="16" />, abbv: "kg" },
    { name: "Laos", flag: <Flag code="la" height="16" />, abbv: "la" },
    { name: "Latvia", flag: <Flag code="lv" height="16" />, abbv: "lv" },
    { name: "Lebanon", flag: <Flag code="lb" height="16" />, abbv: "lb" },
    { name: "Lesotho", flag: <Flag code="ls" height="16" />, abbv: "ls" },
    { name: "Liberia", flag: <Flag code="lr" height="16" />, abbv: "lr" },
    { name: "Libya", flag: <Flag code="ly" height="16" />, abbv: "ly" },
    { name: "Liechtenstein", flag: <Flag code="li" height="16" />, abbv: "li" },
    { name: "Lithuania", flag: <Flag code="lt" height="16" />, abbv: "lt" },
    { name: "Luxembourg", flag: <Flag code="lu" height="16" />, abbv: "lu" },
    { name: "Madagascar", flag: <Flag code="mg" height="16" />, abbv: "mg" },
    { name: "Malawi", flag: <Flag code="mw" height="16" />, abbv: "mw" },
    { name: "Malaysia", flag: <Flag code="my" height="16" />, abbv: "my" },
    { name: "Maldives", flag: <Flag code="mv" height="16" />, abbv: "mv" },
    { name: "Mali", flag: <Flag code="ml" height="16" />, abbv: "ml" },
    { name: "Malta", flag: <Flag code="mt" height="16" />, abbv: "mt" },
    { name: "Marshall Islands", flag: <Flag code="mh" height="16" />, abbv: "mh" },
    { name: "Mauritania", flag: <Flag code="mr" height="16" />, abbv: "mr" },
    { name: "Mauritius", flag: <Flag code="mu" height="16" />, abbv: "mu" },
    { name: "Mexico", flag: <Flag code="mx" height="16" />, abbv: "mx" },
    { name: "Micronesia", flag: <Flag code="fm" height="16" />, abbv: "fm" },
    { name: "Moldova", flag: <Flag code="md" height="16" />, abbv: "md" },
    { name: "Monaco", flag: <Flag code="mc" height="16" />, abbv: "mc" },
    { name: "Mongolia", flag: <Flag code="mn" height="16" />, abbv: "mn" },
    { name: "Montenegro", flag: <Flag code="me" height="16" />, abbv: "me" },
    { name: "Morocco", flag: <Flag code="ma" height="16" />, abbv: "ma" },
    { name: "Mozambique", flag: <Flag code="mz" height="16" />, abbv: "mz" },
    { name: "Myanmar", flag: <Flag code="mm" height="16" />, abbv: "mm" },
    { name: "Namibia", flag: <Flag code="na" height="16" />, abbv: "na" },
    { name: "Nauru", flag: <Flag code="nr" height="16" />, abbv: "nr" },
    { name: "Nepal", flag: <Flag code="np" height="16" />, abbv: "np" },
    { name: "Netherlands", flag: <Flag code="nl" height="16" />, abbv: "nl" },
    { name: "New Zealand", flag: <Flag code="nz" height="16" />, abbv: "nz" },
    { name: "Nicaragua", flag: <Flag code="ni" height="16" />, abbv: "ni" },
    { name: "Niger", flag: <Flag code="ne" height="16" />, abbv: "ne" },
    { name: "Nigeria", flag: <Flag code="ng" height="16" />, abbv: "ng" },
    { name: "North Korea", flag: <Flag code="kp" height="16" />, abbv: "kp" },
    { name: "North Macedonia", flag: <Flag code="mk" height="16" />, abbv: "mk" },
    { name: "Norway", flag: <Flag code="no" height="16" />, abbv: "no" },
    { name: "Oman", flag: <Flag code="om" height="16" />, abbv: "om" },
    { name: "Pakistan", flag: <Flag code="pk" height="16" />, abbv: "pk" },
    { name: "Palau", flag: <Flag code="pw" height="16" />, abbv: "pw" },
    { name: "Panama", flag: <Flag code="pa" height="16" />, abbv: "pa" },
    { name: "Papua New Guinea", flag: <Flag code="pg" height="16" />, abbv: "pg" },
    { name: "Paraguay", flag: <Flag code="py" height="16" />, abbv: "py" },
    { name: "Peru", flag: <Flag code="pe" height="16" />, abbv: "pe" },
    { name: "Philippines", flag: <Flag code="ph" height="16" />, abbv: "ph" },
    { name: "Poland", flag: <Flag code="pl" height="16" />, abbv: "pl" },
    { name: "Portugal", flag: <Flag code="pt" height="16" />, abbv: "pt" },
    { name: "Qatar", flag: <Flag code="qa" height="16" />, abbv: "qa" },
    { name: "Romania", flag: <Flag code="ro" height="16" />, abbv: "ro" },
    { name: "Russia", flag: <Flag code="ru" height="16" />, abbv: "ru" },
    { name: "Rwanda", flag: <Flag code="rw" height="16" />, abbv: "rw" },
    { name: "Saint Kitts and Nevis", flag: <Flag code="kn" height="16" />, abbv: "kn" },
    { name: "Saint Lucia", flag: <Flag code="lc" height="16" />, abbv: "lc" },
    { name: "Saint Vincent and the Grenadines", flag: <Flag code="vc" height="16" />, abbv: "vc" },
    { name: "Samoa", flag: <Flag code="ws" height="16" />, abbv: "ws" },
    { name: "San Marino", flag: <Flag code="sm" height="16" />, abbv: "sm" },
    { name: "Sao Tome and Principe", flag: <Flag code="st" height="16" />, abbv: "st" },
    { name: "Saudi Arabia", flag: <Flag code="sa" height="16" />, abbv: "sa" },
    { name: "Senegal", flag: <Flag code="sn" height="16" />, abbv: "sn" },
    { name: "Serbia", flag: <Flag code="rs" height="16" />, abbv: "rs" },
    { name: "Seychelles", flag: <Flag code="sc" height="16" />, abbv: "sc" },
    { name: "Sierra Leone", flag: <Flag code="sl" height="16" />, abbv: "sl" },
    { name: "Singapore", flag: <Flag code="sg" height="16" />, abbv: "sg" },
    { name: "Slovakia", flag: <Flag code="sk" height="16" />, abbv: "sk" },
    { name: "Slovenia", flag: <Flag code="si" height="16" />, abbv: "si" },
    { name: "Solomon Islands", flag: <Flag code="sb" height="16" />, abbv: "sb" },
    { name: "Somalia", flag: <Flag code="so" height="16" />, abbv: "so" },
    { name: "South Africa", flag: <Flag code="za" height="16" />, abbv: "za" },
    { name: "South Korea", flag: <Flag code="kr" height="16" />, abbv: "kr" },
    { name: "South Sudan", flag: <Flag code="ss" height="16" />, abbv: "ss" },
    { name: "Spain", flag: <Flag code="es" height="16" />, abbv: "es" },
    { name: "Sri Lanka", flag: <Flag code="lk" height="16" />, abbv: "lk" },
    { name: "Sudan", flag: <Flag code="sd" height="16" />, abbv: "sd" },
    { name: "Suriname", flag: <Flag code="sr" height="16" />, abbv: "sr" },
    { name: "Sweden", flag: <Flag code="se" height="16" />, abbv: "se" },
    { name: "Switzerland", flag: <Flag code="ch" height="16" />, abbv: "ch" },
    { name: "Syria", flag: <Flag code="sy" height="16" />, abbv: "sy" },
    { name: "Taiwan", flag: <Flag code="tw" height="16" />, abbv: "tw" },
    { name: "Tajikistan", flag: <Flag code="tj" height="16" />, abbv: "tj" },
    { name: "Tanzania", flag: <Flag code="tz" height="16" />, abbv: "tz" },
    { name: "Thailand", flag: <Flag code="th" height="16" />, abbv: "th" },
    { name: "Togo", flag: <Flag code="tg" height="16" />, abbv: "tg" },
    { name: "Tonga", flag: <Flag code="to" height="16" />, abbv: "to" },
    { name: "Trinidad and Tobago", flag: <Flag code="tt" height="16" />, abbv: "tt" },
    { name: "Tunisia", flag: <Flag code="tn" height="16" />, abbv: "tn" },
    { name: "Turkey", flag: <Flag code="tr" height="16" />, abbv: "tr" },
    { name: "Turkmenistan", flag: <Flag code="tm" height="16" />, abbv: "tm" },
    { name: "Tuvalu", flag: <Flag code="tv" height="16" />, abbv: "tv" },
    { name: "Uganda", flag: <Flag code="ug" height="16" />, abbv: "ug" },
    { name: "Ukraine", flag: <Flag code="ua" height="16" />, abbv: "ua" },
    { name: "United Arab Emirates", flag: <Flag code="ae" height="16" />, abbv: "ae" },
    { name: "United Kingdom", flag: <Flag code="gb" height="16" />, abbv: "gb" },
    { name: "United States", flag: <Flag code="us" height="16" />, abbv: "us" },
    { name: "Uruguay", flag: <Flag code="uy" height="16" />, abbv: "uy" },
    { name: "Uzbekistan", flag: <Flag code="uz" height="16" />, abbv: "uz" },
    { name: "Vanuatu", flag: <Flag code="vu" height="16" />, abbv: "vu" },
    { name: "Vatican City", flag: <Flag code="va" height="16" />, abbv: "va" },
    { name: "Venezuela", flag: <Flag code="ve" height="16" />, abbv: "ve" },
    { name: "Vietnam", flag: <Flag code="vn" height="16" />, abbv: "vn" },
    { name: "Yemen", flag: <Flag code="ye" height="16" />, abbv: "ye" },
    { name: "Zambia", flag: <Flag code="zm" height="16" />, abbv: "zm" },
    { name: "Zimbabwe", flag: <Flag code="zw" height="16" />, abbv: "zw" }
  ];



  useEffect(() => {
    getUsers();
  }, []);


  const getUsers = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        if (response?.status !== false) {
          setData(response?.data)
          setValue("username", response?.data?.username);
          setValue("email", response?.data?.email);
          setValue("country", response?.data?.country);
        }
        updateLoading(false);
      })
      .catch((error) => {
        updateLoading(false);
        console.log(error);
      });
  };



  // Function to handle form submission
  const onSubmit = (data) => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("username", data.username);
    formData.append("email", data.email);
    formData.append("country", data.country);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "post",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === false) {
          toast.error("El campo username y email deben ser unicos");
        } else {
          toast.success("Información guardada con exito");
          getUsers();
        }
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
    // Aquí puedes manejar la lógica de envío
  };

  function currencyFormatter(numero) {
    return numero.toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  }

  return (
    <div>
      <h1 className="title-main">{t("_SETTINGS._TITLE")}</h1>
      <div className="signin edit-profile d-grid gap-3 grid-auto-200">
        <div className="signin-inner edit-profile">
          <div className="row  g-3">
            <div className="form-wrapper d-flex flex-column align-items-center">
              <form className="d-flex flex-column align-items-center" onSubmit={handleSubmit(onSubmit)}>
                <h4 className="text-white border-b-blue full-block">
                  {t("_SETTINGS._TABLE1")}
                </h4>

                <div className="d-lg-grid gap-3 mt-4 d-sm-flex full-block" style={{ gridTemplateColumns: "50% 50%" }}>
                  <input
                    type="text"
                    id="username"
                    placeholder={t("_SETTINGS._TABLE1_A")}
                    className={`form-control ${errors.username ? "is-invalid" : ""}`}
                    {...register("username", { required: true })}
                  />
                  {errors.username && <p className="text-danger">Username is required</p>}

                  <input
                    type="email"
                    id="email"
                    placeholder={t("_SETTINGS._TABLE1_D")}
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    {...register("email", { required: true })}
                  />
                  {errors.email && <p className="text-danger">Email is required</p>}

                  <select
                    id="country"
                    className={`nice-select ${errors.country ? "is-invalid" : ""}`}
                    {...register("country", { required: true })}
                  >
                    <option value="">{t("_SETTINGS._TABLE1_C")}</option>
                    {countries.map((country) => (
                      <option key={country.name} value={country.abbv}>
                        {country.abbv} -  {country.name}
                      </option>
                    ))}
                  </select>
                  {errors.country && <p className="text-danger">Country is required</p>}
                </div>
                <div className="fs-12 d-flex gap-2 flex-wrap justify-content-center buttom-group width70 pt-5">
                  <button
                    type="submit"
                    className="social-login-button d-flex justify-content-center align-items-center px-4"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="signin-inner edit-profile status d-flex  flex-column align-items-center position-relative w-200">
          <img className="" src={profile} alt="" />
          {data?.active === "0" ?
            <div className="data-user ta-center">
              <h6 className="fw-bold">{data?.username ? data?.username : "-"}  <Flag code={data?.country} height="16" fallback={<span>-</span>} /></h6>
              <button type="button" className="btn btn-danger position-relative my-2">
                No Active
              </button>
            </div>
            :
            <>
              <div className="data-user ta-center">
                <h6 className="fw-bold">{data?.username ? data?.username : "-"} - W{data?.package}  </h6><Flag code={data?.country} height="16" fallback={<span>-</span>} />

              </div>
            </>
          }

          <div className="grid ">
            <div className=" ta-center">
              <p className="fs-6">Balance Total</p>
              <p className="bitcoin-text">
                🌊
                Wavees
              </p>
              <h3 className="fw-bold  fs-3">{currencyFormatter(parseFloat(data?.balance || 0) + parseFloat(data?.balance_wavees || 0))}</h3>
              <p className="f14-w400 green fs-6 fw-light">{currencyFormatter(parseFloat(data?.balance || 0) + parseFloat(data?.balance_wavees || 0))} USD</p>
            </div>
          </div>
          <img className="w-100 position-absolute" src={grafic} alt="" />
        </div>
      </div>
    </div>
  );
}
