import React, { useState, useEffect, useContext } from "react";
import AppContext from "../context/AppContex";
import { useForm } from 'react-hook-form';
import queryString from "query-string";
import toast from "react-hot-toast";
import profitwave from "../assets/svg/profitwave.svg";
import bgLines from "../assets/svg/bg-lines.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAccount, useSignMessage } from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'

export default function Register() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [message, setMessage] = useState('Please sign this message to log in.');
  const [sponsorID, setSponsorID] = useState("");
  const [sponsorData, setSponsorData] = useState();
  const valores = queryString.parse(location.search);
  const [checkTerms, setCheckTerms] = useState(true);
  const { isConnected, address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const { open, close } = useWeb3Modal()
  const { updateLoading } = useContext(AppContext);
  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();



  useEffect(() => {
    // Obtener el valor del parámetro 'invite' de la URL
    const values = queryString.parse(window.location.search);
    if (values.invite) {
      setValue('referralCode', values.invite);
      setSponsorID(values.invite)
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token"); //Verifico si tiene token
    if (isConnected && !token) {
      handleSignMessage()
    }
  }, [isConnected]);



  const searchSponsor = (data) => {
    updateLoading(true);
    setSponsorID(data?.referralCode)
    const formData = new FormData();
    formData.append("code", data?.referralCode);
    fetch(`${process.env.REACT_APP_BACKEND}/referees`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status == false) {
          toast.error("No existe patrocinador, vuelve a intentarlo");
        } else {
          toast.success("Patrocinador enlazado, connecte su wallet");
          goToLogin();
        }
        updateLoading(false);
      })
      .catch((error) => {
        toast.error("No existe patrocinador, vuelve a intentarlo");
        updateLoading(false);
        console.log(error);
      });
  }


  const handleSignMessage = async () => {
    if (!isConnected) {
      toast.error("Please connect your wallet first");
      return;
    }
    try {
      const signedMessage = await signMessageAsync({ message });
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address,
          signedMessage,
          referred: sponsorID,
        }),
      });
      const data = await response.json();
      if (data.status === "OK") {
        console.log(data)
        localStorage.setItem("token", data?.token);
        localStorage.setItem("address", data?.user?.address);
        localStorage.setItem("id", data?.user?.id);
        localStorage.setItem("user", JSON.stringify(data?.user));
        localStorage.setItem("referral", data?.user?.referred_my);
        toast.success("Login Success");
        navigate("/");
      } else {
        toast.error("An error occurred during signing");
      }
    } catch (error) {
      toast.error("An error occurred during signing");
    }
  };


  const goToLogin = async () => {
    if (!isConnected) {
      open();
    } else {
      handleSignMessage()
    }
  };
  return (
    <>
      <section className="signin">
        <div className="signin-header bg-blue full-block row d-flex align-items-left">
          <img className="img-fluid" src={bgLines} alt="" />
        </div>
        <div className="signin-inner w-400 ">
          <div className="row d-flex align-items-center g-3">
            <div className="form-wrapper d-flex flex-column align-items-center">
              <img className="img-fluid" src={profitwave} alt="" />
              <div className="d-flex flex-column align-items-center">
                <h4 className="text-center text-white border-b-blue full-block">{t("_REGISTER._REGISTER")}</h4>

                {!sponsorData ?
                  <>
                    <form onSubmit={handleSubmit(searchSponsor)}>
                      <input
                        placeholder={`ID ${t("_REGISTER._SPONSOR")}`}
                        className="form-control form-control py-2"
                        id="referralCode"
                        type="text"
                        {...register('referralCode')}
                      />
                      <br />
                      <input
                        name="user-feedback"
                        value="item spacing"
                        onChange={(e) => setCheckTerms(!checkTerms)}
                        type="checkbox" />
                      <label className="fs-6">{t("_REGISTER._ACEPT")}<a href="#" className="fs-6">{t("_REGISTER._TERMS")}</a> </label>
                      <div className=" fs-12 d-flex gap-2 flex-wrap justify-content-center buttom-group width70 pt-3 ">
                        <button className="social-login-button d-flex justify-content-center align-items-center px-4" disabled={checkTerms} type="submit">
                          {t("_REGISTER._SEARCHSPONSOR")}
                        </button>
                      </div>
                    </form>
                  </>
                  :
                  <>
                    <form className="form-segment mb-3 mt-4">

                    </form>

                    <div className=" fs-12 d-flex gap-2 flex-wrap justify-content-center buttom-group width70 pt-3 ">
                      <button className="social-login-button d-flex justify-content-center align-items-center px-4" disabled={!checkTerms} onClick={() => goToLogin()}>
                        {t("_REGISTER._REGISTER")}
                      </button>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};