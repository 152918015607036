import { useState, useContext, ReactNode } from "react";
import AppContext from "../context/AppContex";
import BuyModal from "./BuyModal";
import RechargeModal from "./RechargeModal";
import LevelModal from "./LevelModal";
export default function AllModal() {
  const { state } = useContext(AppContext);
  return (
    <>
      {state?.modal.buy && <BuyModal />}
      {state?.modal.recharge && <RechargeModal />}
      {state?.modal.level && <LevelModal />}
    </>
  );
}
