import React, { useState, useEffect, useContext } from "react";
import AppContext from "../context/AppContex";
import { useTranslation } from "react-i18next";

const LevelModal = () => {
  const { t } = useTranslation();
  const { modalBackground, modalRegister, modalLevel } = useContext(AppContext);

  const goToClosed = () => {
    modalLevel(false);
    modalBackground(false);
  };
  return (
    <>
      <div
        className="modal-parent"
        style={{ cursor: "pointer", textAlign: "center" }}
      >
        <div className="modal-backdrop">
          <div className="modal" style={{ textAlign: "center" }}>
            <>
              <div className="center">
                <h3>{t("_MODALEVEL._TITLE")}</h3>
                <p>{t("_MODALEVEL._DESCRIPTION")}</p>
                <button onClick={() => goToClosed()}>
                  <span>{t("_BUTTON._CLOSED")}</span>
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default LevelModal;
