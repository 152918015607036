import React, { useEffect, useState, useContext } from "react";

export default function Loading() {
    return (
        <>
            <div className="modal fade show d-block" tabIndex="-1" aria-labelledby="exampleModalLiveLabel" aria-modal="true" role="dialog"
                style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <div className="container-loader">
                    <div className="wave-animation">🌊</div>
                    <div className="text text-w">w</div>
                    <div className="text text-a">a</div>
                    <div className="text text-v">v</div>
                    <div className="text text-e">e</div>
                    <div className="text text-ee">e</div>
                    <div className="text text-s">s</div>
                </div>
            </div>
        </>
    )
}
