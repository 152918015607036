import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import barras from "../assets/svg/barras.svg";
import barrasverdes from "../assets/svg/barrasverdes.svg";
import bitcoin from "../assets/crypto/bitcoin.png";
import etherium from "../assets/crypto/etherium.png";
import dogecoin from "../assets/crypto/dogecoin.png";
import dolar from "../assets/svg/dolar.svg";
import grafic from "../assets/svg/grafic.svg";
import AppContext from "../context/AppContex";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export default function Wallet() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const { updateLoading, loading } = useContext(AppContext);

  useEffect(() => {
    getWallet();
  }, []);


  const getWallet = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/wallet`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status !== false) {
          setData(response?.response);
          let responsetemp = response?.response;
          setTotal(parseFloat(responsetemp?.balance) + parseFloat(responsetemp?.balance_wavees));
        }
        updateLoading(false);
      })
      .catch((error) => {
        updateLoading(false);
        console.log(error);
      });
  };


  useEffect(() => {
    const address = window.localStorage.getItem("address");
    if (address) {
      setAddress(address);
    }
  }, []);

  function currencyFormatter(numero) {
    return numero.toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  }

  return (
    <>
      <section className="container-full bg1">
        <div className="container" style={{ color: 'black' }}>
          <h1 className="title-main">
            {t("_WALLET._TITLE")}
          </h1>
          <div>
            <div className="d-grid grid-200-auto gap-b-mobile">
              <div className="grid purple position-relative w-200 p-10-10-90 br-6">
                <div className=" ta-center d-flex flex-column align-items-center ">
                  <p className="fs-6">{t("_WALLET._BALANCE")}<img src={dolar} alt="dolar" style={{ width: '56px', marginLeft: '5px' }} /></p>
                  <p className="bitcoin-text">🌊 Wavees</p>
                  <h3 className="fw-bold  fs-3">{currencyFormatter(parseFloat(data?.balance || 0) + parseFloat(data?.balance_wavees || 0))}</h3>
                  <p className="f14-w400 green fs-6 fw-light">{currencyFormatter(parseFloat(data?.balance || 0) + parseFloat(data?.balance_wavees || 0))} USD</p>
                  <div className=" fs-12 d-grid gap-2 flex-wrap buttom-group pt-3">
                    <button className="green button-generic fs-6 py-1 px-1" style={{ marginRight: '0' }}>
                      {t("_BUTTONS._WITHDRAW")}
                    </button>
                  </div>
                </div>
                <img className="w-100 position-absolute" style={{ bottom: '0px', left: '0px' }} src={grafic} alt="" />
              </div>
              <div className="container-blue">
                <div className="full-column">
                  <div className="grid">
                    <div>
                      <p>Balance para retirar</p>
                      <h3>{parseFloat(data?.balance || 0)}</h3>
                      <p className="f14-w400 green">{currencyFormatter(parseFloat(data?.balance || 0))}  USD</p>
                    </div>
                    <div>
                      <img className="" src={barras} alt="" style={{ width: "100%" }} />
                    </div>
                  </div>
                  <progress id="initialProgress" max="100" value={'100'} style={{ width: "100%" }} className="pink"></progress>
                  <p className="f14-w400 ta-right"></p>
                </div>
                <div className="full-column">
                  <div className="grid">
                    <div>
                      <p>Balance bloqueado por airdrop</p>
                      <h3>{currencyFormatter(parseFloat(data?.balance_wavees || 0))} </h3>
                    </div>
                    <div>
                      <img className="" src={barrasverdes} alt="" style={{ width: "100%" }} />
                    </div>
                  </div>
                  <progress id="initialProgress" max="100" value={'100'} style={{ width: "100%" }} className="green"></progress>
                  <p className="f14-w400 ta-right"></p>
                </div>
              </div>
            </div>
            <div className="row my-2 g-3 g-lg-4">
              <div className="col-md-6 col-xl-4 col-xxl-3">
                <div className="price-box">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="text-white mb-0">Total Ganado por Artbitraje</p>
                      <h4 className="fw-semibold text-white mb-0">{parseFloat(data?.total_balance || 0)} 🌊</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 col-xxl-3">
                <div className="price-box">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="text-white mb-0">Total Ganado por Equipo</p>
                      <h4 className="fw-semibold text-white mb-0">{parseFloat(data?.total_referred || 0)} 🌊</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 col-xxl-3">
                <div className="price-box">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="text-white mb-0">Total de Wavees retirados</p>
                      <h4 className="fw-semibold text-white mb-0">{parseFloat(data?.total_withdrawal || 0)} 🌊</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 col-xxl-3">
                <div className="price-box">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="text-white mb-0">Total paquetes comprado</p>
                      <h4 className="fw-semibold text-white mb-0">{parseFloat(data?.total_deposit || 0)} 🌊</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-xxl-3">
                <div className="price-box">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="text-white mb-0">Total Airdrop Recibido</p>
                      <h4 className="fw-semibold text-white mb-0">{parseFloat(data?.total_balance_wavees || 0)} 🌊</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
