export const abi = [
    {
        name: 'executeFlashLoan',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [
            { internalType: 'address', name: 'contractAddress', type: 'address' },
            {
                internalType: 'struct Profitpool.structSalesOrder',
                name: 'salesOrder',
                type: 'tuple',
                components: [
                    { internalType: 'address', name: 'contractAddress', type: 'address' },
                    { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                    { internalType: 'uint256', name: 'amountTotal', type: 'uint256' },
                    { internalType: 'uint256', name: 'amountPayment', type: 'uint256' },
                    { internalType: 'uint256', name: 'actionsTotal', type: 'uint256' },
                    { internalType: 'uint256', name: 'actions', type: 'uint256' },
                    { internalType: 'address', name: 'tokenOwner', type: 'address' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                    { internalType: 'string', name: 'tokenURI', type: 'string' },
                    { internalType: 'uint256', name: 'nonce', type: 'uint256' },
                    { internalType: 'bytes', name: 'signature', type: 'bytes' }
                ]
            }
        ],
        outputs: []
    },
    {
        name: 'executeWithdrawall',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [
            { internalType: 'address', name: 'contractAddress', type: 'address' },
            {
                internalType: 'struct Profitpool.structSalesOrder',
                name: 'salesOrder',
                type: 'tuple',
                components: [
                    { internalType: 'address', name: 'contractAddress', type: 'address' },
                    { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                    { internalType: 'uint256', name: 'amountTotal', type: 'uint256' },
                    { internalType: 'uint256', name: 'amountPayment', type: 'uint256' },
                    { internalType: 'uint256', name: 'actionsTotal', type: 'uint256' },
                    { internalType: 'uint256', name: 'actions', type: 'uint256' },
                    { internalType: 'address', name: 'tokenOwner', type: 'address' },
                    { internalType: 'uint256', name: 'price', type: 'uint256' },
                    { internalType: 'string', name: 'tokenURI', type: 'string' },
                    { internalType: 'uint256', name: 'nonce', type: 'uint256' },
                    { internalType: 'bytes', name: 'signature', type: 'bytes' }
                ]
            }
        ],
        outputs: []
    },
    {
        name: 'receiveUSDT',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [
            { internalType: 'uint256', name: 'amount', type: 'uint256' }
        ],
        outputs: []
    },
    {
        name: 'renounceOwnership',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [],
        outputs: []
    },
    {
        name: 'transferOwnership',
        type: 'function',
        stateMutability: 'nonpayable',
        inputs: [
            { internalType: 'address', name: 'newOwner', type: 'address' }
        ],
        outputs: []
    },
    {
        name: '_invalidatedSalesOrders',
        type: 'function',
        stateMutability: 'view',
        inputs: [
            { internalType: 'bytes32', name: '', type: 'bytes32' }
        ],
        outputs: [
            { internalType: 'bool', name: '', type: 'bool' }
        ]
    },
    {
        name: '_salesOrderNonces',
        type: 'function',
        stateMutability: 'view',
        inputs: [
            { internalType: 'address', name: '', type: 'address' }
        ],
        outputs: [
            { internalType: 'uint256', name: '_value', type: 'uint256' }
        ]
    },
    {
        name: 'getCurrentSalesOrderNonce',
        type: 'function',
        stateMutability: 'view',
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' }
        ],
        outputs: [
            { internalType: 'uint256', name: '', type: 'uint256' }
        ]
    },
    {
        name: 'getERC20Balance',
        type: 'function',
        stateMutability: 'view',
        inputs: [],
        outputs: [
            { internalType: 'uint256', name: '', type: 'uint256' }
        ]
    },
    {
        name: 'owner',
        type: 'function',
        stateMutability: 'view',
        inputs: [],
        outputs: [
            { internalType: 'address', name: '', type: 'address' }
        ]
    },
    {
        name: 'userbalances',
        type: 'function',
        stateMutability: 'view',
        inputs: [
            { internalType: 'uint256', name: '', type: 'uint256' }
        ],
        outputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'uint256', name: 'amountpaid', type: 'uint256' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'uint256', name: 'time', type: 'uint256' },
            { internalType: 'uint256', name: 'referredId', type: 'uint256' },
            { internalType: 'uint256', name: 'referredCount', type: 'uint256' },
            { internalType: 'string', name: 'link1', type: 'string' },
            { internalType: 'string', name: 'link2', type: 'string' },
            { internalType: 'string', name: 'link3', type: 'string' },
            { internalType: 'string', name: 'link4', type: 'string' },
            { internalType: 'string', name: 'link5', type: 'string' }
        ]
    }
] as const;
